/* tslint:disable */
/* eslint-disable */
/**
 * HackTricks Training
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AWSAccountState = {
    Pending: 'Pending',
    Available: 'Available',
    Locked: 'Locked',
    Provisioning: 'Provisioning',
    Provisioned: 'Provisioned',
    Dirty: 'Dirty',
    Nuking: 'Nuking',
    Nuked: 'Nuked'
} as const;

export type AWSAccountState = typeof AWSAccountState[keyof typeof AWSAccountState];


/**
 * 
 * @export
 * @interface ActivateAccountPayload
 */
export interface ActivateAccountPayload {
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountPayload
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ActivateAccountResponse
 */
export interface ActivateAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateAccountResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ActivateEmailUserResponse
 */
export interface ActivateEmailUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateEmailUserResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ActivateVoucherPayload
 */
export interface ActivateVoucherPayload {
    /**
     * 
     * @type {string}
     * @memberof ActivateVoucherPayload
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ActivateVoucherResponse
 */
export interface ActivateVoucherResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateVoucherResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface AddExtraDataLabPayload
 */
export interface AddExtraDataLabPayload {
    /**
     * 
     * @type {string}
     * @memberof AddExtraDataLabPayload
     */
    'gcp_whitebox_email': string;
}
/**
 * 
 * @export
 * @interface AddExtraDataLabResponse
 */
export interface AddExtraDataLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof AddExtraDataLabResponse
     */
    'user_lab': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof AddExtraDataLabResponse
     */
    'success': boolean;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminAWSAccountSchema
 */
export interface AdminAWSAccountSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminAWSAccountSchema
     */
    'id': string;
    /**
     * 
     * @type {AWSAccountState}
     * @memberof AdminAWSAccountSchema
     */
    'state': AWSAccountState;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminCertificateSchema
 */
export interface AdminCertificateSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminCertificateSchema
     */
    'id': string;
    /**
     * 
     * @type {PublicUserSchema}
     * @memberof AdminCertificateSchema
     */
    'user': PublicUserSchema;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof AdminCertificateSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {string}
     * @memberof AdminCertificateSchema
     */
    'awarded_at': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCertificateSchema
     */
    'full_name'?: string;
    /**
     * 
     * @type {object}
     * @memberof AdminCertificateSchema
     */
    'data'?: object;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminCourseSchema
 */
export interface AdminCourseSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'logo': string;
    /**
     * 
     * @type {object}
     * @memberof AdminCourseSchema
     */
    'syllabus'?: object;
    /**
     * 
     * @type {object}
     * @memberof AdminCourseSchema
     */
    'faqs'?: object;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSchema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSchema
     */
    'sales_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseSchema
     */
    'max_sales'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSchema
     */
    'prereleased': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'release_date'?: string;
    /**
     * 
     * @type {Array<AdminSectionSchema>}
     * @memberof AdminCourseSchema
     */
    'sections': Array<AdminSectionSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSchema
     */
    'has_exam': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'changelog'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCourseSchema
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCourseSchema
     */
    'promotion_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCourseSchema
     */
    'discord_roles': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCourseSchema
     */
    'certified_discord_roles': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminDashboardARTAVouchers
 */
export interface AdminDashboardARTAVouchers {
    /**
     * 
     * @type {string}
     * @memberof AdminDashboardARTAVouchers
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardARTAVouchers
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdminDashboardARTEVouchers
 */
export interface AdminDashboardARTEVouchers {
    /**
     * 
     * @type {string}
     * @memberof AdminDashboardARTEVouchers
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardARTEVouchers
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdminDashboardAWSAccount
 */
export interface AdminDashboardAWSAccount {
    /**
     * 
     * @type {AWSAccountState}
     * @memberof AdminDashboardAWSAccount
     */
    'state': AWSAccountState;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardAWSAccount
     */
    'count': number;
}


/**
 * 
 * @export
 * @interface AdminDashboardCertificates
 */
export interface AdminDashboardCertificates {
    /**
     * 
     * @type {string}
     * @memberof AdminDashboardCertificates
     */
    'course': string;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardCertificates
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdminDashboardData
 */
export interface AdminDashboardData {
    /**
     * 
     * @type {Array<AdminDashboardAWSAccount>}
     * @memberof AdminDashboardData
     */
    'aws_accounts': Array<AdminDashboardAWSAccount>;
    /**
     * 
     * @type {Array<AdminDashboardGCPProject>}
     * @memberof AdminDashboardData
     */
    'gcp_projects': Array<AdminDashboardGCPProject>;
    /**
     * 
     * @type {Array<AdminDashboardUsers>}
     * @memberof AdminDashboardData
     */
    'users': Array<AdminDashboardUsers>;
    /**
     * 
     * @type {Array<AdminDashboardARTEVouchers>}
     * @memberof AdminDashboardData
     */
    'arte_vouchers': Array<AdminDashboardARTEVouchers>;
    /**
     * 
     * @type {Array<AdminDashboardARTAVouchers>}
     * @memberof AdminDashboardData
     */
    'arta_vouchers': Array<AdminDashboardARTAVouchers>;
    /**
     * 
     * @type {Array<AdminDashboardGRTEVouchers>}
     * @memberof AdminDashboardData
     */
    'grte_vouchers': Array<AdminDashboardGRTEVouchers>;
    /**
     * 
     * @type {Array<AdminDashboardCertificates>}
     * @memberof AdminDashboardData
     */
    'certificates': Array<AdminDashboardCertificates>;
}
/**
 * 
 * @export
 * @interface AdminDashboardGCPProject
 */
export interface AdminDashboardGCPProject {
    /**
     * 
     * @type {GCPAccountState}
     * @memberof AdminDashboardGCPProject
     */
    'state': GCPAccountState;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardGCPProject
     */
    'count': number;
}


/**
 * 
 * @export
 * @interface AdminDashboardGRTEVouchers
 */
export interface AdminDashboardGRTEVouchers {
    /**
     * 
     * @type {string}
     * @memberof AdminDashboardGRTEVouchers
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardGRTEVouchers
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdminDashboardUsers
 */
export interface AdminDashboardUsers {
    /**
     * 
     * @type {string}
     * @memberof AdminDashboardUsers
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardUsers
     */
    'count': number;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminExamFlagSchema
 */
export interface AdminExamFlagSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminExamFlagSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminExamFlagSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof AdminExamFlagSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExamFlagSchema
     */
    'description': string;
    /**
     * 
     * @type {object}
     * @memberof AdminExamFlagSchema
     */
    'tf_outputs': object;
    /**
     * 
     * @type {FlagType}
     * @memberof AdminExamFlagSchema
     */
    'type': FlagType;
    /**
     * 
     * @type {string}
     * @memberof AdminExamFlagSchema
     */
    'value': string;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminExamSchema
 */
export interface AdminExamSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof AdminExamSchema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof AdminExamSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminExamSchema
     */
    'duration_hours': number;
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'platform': string;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof AdminExamSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {Array<AdminExamFlagSchema>}
     * @memberof AdminExamSchema
     */
    'examflags': Array<AdminExamFlagSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExamSchema
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminExamSchema
     */
    'promotion_code'?: string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminFlagSchema
 */
export interface AdminFlagSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminFlagSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminFlagSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof AdminFlagSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlagSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFlagSchema
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof AdminFlagSchema
     */
    'tf_outputs': object;
    /**
     * 
     * @type {FlagType}
     * @memberof AdminFlagSchema
     */
    'type': FlagType;
    /**
     * 
     * @type {string}
     * @memberof AdminFlagSchema
     */
    'value': string;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminGCPProjectSchema
 */
export interface AdminGCPProjectSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminGCPProjectSchema
     */
    'id': string;
    /**
     * 
     * @type {GCPAccountState}
     * @memberof AdminGCPProjectSchema
     */
    'state': GCPAccountState;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminLabSchema
 */
export interface AdminLabSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof AdminLabSchema
     */
    'price15': number;
    /**
     * 
     * @type {number}
     * @memberof AdminLabSchema
     */
    'price30': number;
    /**
     * 
     * @type {number}
     * @memberof AdminLabSchema
     */
    'price90': number;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof AdminLabSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {number}
     * @memberof AdminLabSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminLabSchema
     */
    'prereleased': boolean;
    /**
     * 
     * @type {Array<AdminFlagSchema>}
     * @memberof AdminLabSchema
     */
    'flags': Array<AdminFlagSchema>;
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'platform': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminLabSchema
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminLabSchema
     */
    'promotion_code'?: string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminLessonSchema
 */
export interface AdminLessonSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminLessonSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLessonSchema
     */
    'references': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'slides': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'video': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminLessonSchema
     */
    'is_lab_deployable': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLessonSchema
     */
    'extra_tf_variables': Array<string>;
    /**
     * 
     * @type {Array<AdminFlagSchema>}
     * @memberof AdminLessonSchema
     */
    'flags': Array<AdminFlagSchema>;
    /**
     * 
     * @type {string}
     * @memberof AdminLessonSchema
     */
    'created_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLessonSchema
     */
    'lab_tf_resources': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminResetLabResponse
 */
export interface AdminResetLabResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AdminResetLabResponse
     */
    'success': boolean;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminSectionSchema
 */
export interface AdminSectionSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminSectionSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AdminSectionSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof AdminSectionSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSectionSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSectionSchema
     */
    'description': string;
    /**
     * 
     * @type {Array<AdminLessonSchema>}
     * @memberof AdminSectionSchema
     */
    'lessons': Array<AdminLessonSchema>;
    /**
     * 
     * @type {string}
     * @memberof AdminSectionSchema
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface AdminUserCourseSchema
 */
export interface AdminUserCourseSchema {
    /**
     * 
     * @type {AdminUserSchema}
     * @memberof AdminUserCourseSchema
     */
    'user': AdminUserSchema;
    /**
     * 
     * @type {AdminCourseSchema}
     * @memberof AdminUserCourseSchema
     */
    'course': AdminCourseSchema;
    /**
     * 
     * @type {object}
     * @memberof AdminUserCourseSchema
     */
    'data'?: object;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface AdminUserSchema
 */
export interface AdminUserSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSchema
     */
    'discord'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserSchema
     */
    'is_active': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserSchema
     */
    'roles': Array<string>;
    /**
     * 
     * @type {Array<EmailSubscriptionEnum>}
     * @memberof AdminUserSchema
     */
    'email_subscriptions': Array<EmailSubscriptionEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserSchema
     */
    'is_terms_accepted': boolean;
}
/**
 * 
 * @export
 * @interface AdminVoucherSchema
 */
export interface AdminVoucherSchema {
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'redeemed_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVoucherSchema
     */
    'is_used': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVoucherSchema
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface CheckHasExamFlagResponse
 */
export interface CheckHasExamFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckHasExamFlagResponse
     */
    'completed': boolean;
}
/**
 * 
 * @export
 * @interface CheckHasFlagResponse
 */
export interface CheckHasFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckHasFlagResponse
     */
    'completed': boolean;
}
/**
 * 
 * @export
 * @interface CompleteLessonResponse
 */
export interface CompleteLessonResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompleteLessonResponse
     */
    'success': boolean;
    /**
     * 
     * @type {UserCourseSchema}
     * @memberof CompleteLessonResponse
     */
    'data': UserCourseSchema;
}
/**
 * 
 * @export
 * @interface CreateCourseVoucherRequest
 */
export interface CreateCourseVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseVoucherRequest
     */
    'user_username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseVoucherRequest
     */
    'course_slug': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCourseVoucherRequest
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CreateCourseVoucherResponse
 */
export interface CreateCourseVoucherResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCourseVoucherResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateExamVoucherRequest
 */
export interface CreateExamVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExamVoucherRequest
     */
    'user_username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExamVoucherRequest
     */
    'exam_slug': string;
    /**
     * 
     * @type {number}
     * @memberof CreateExamVoucherRequest
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CreateExamVoucherResponse
 */
export interface CreateExamVoucherResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateExamVoucherResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateLabVoucherRequest
 */
export interface CreateLabVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLabVoucherRequest
     */
    'user_username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLabVoucherRequest
     */
    'lab_slug': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLabVoucherRequest
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLabVoucherRequest
     */
    'duration': number;
}
/**
 * 
 * @export
 * @interface CreateLabVoucherResponse
 */
export interface CreateLabVoucherResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateLabVoucherResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteAWSAccountResponse
 */
export interface DeleteAWSAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAWSAccountResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteCertificateResponse
 */
export interface DeleteCertificateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCertificateResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteCourseResponse
 */
export interface DeleteCourseResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCourseResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteExamResponse
 */
export interface DeleteExamResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteExamResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteGCPProjectResponse
 */
export interface DeleteGCPProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteGCPProjectResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteLabResponse
 */
export interface DeleteLabResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeleteUserResponse
 */
export interface DeleteUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUserResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DeployLessonLabResponse
 */
export interface DeployLessonLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof DeployLessonLabResponse
     */
    'lab_data': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof DeployLessonLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface DestroyLessonLabResponse
 */
export interface DestroyLessonLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof DestroyLessonLabResponse
     */
    'lab_data': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof DestroyLessonLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface EditAWSAccountResponse
 */
export interface EditAWSAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditAWSAccountResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminAWSAccountSchema}
     * @memberof EditAWSAccountResponse
     */
    'account'?: AdminAWSAccountSchema;
}
/**
 * 
 * @export
 * @interface EditCertificateResponse
 */
export interface EditCertificateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditCertificateResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminCertificateSchema}
     * @memberof EditCertificateResponse
     */
    'certificate'?: AdminCertificateSchema;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface EditCourseRequest
 */
export interface EditCourseRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'logo': string;
    /**
     * 
     * @type {object}
     * @memberof EditCourseRequest
     */
    'syllabus'?: object;
    /**
     * 
     * @type {object}
     * @memberof EditCourseRequest
     */
    'faqs'?: object;
    /**
     * 
     * @type {number}
     * @memberof EditCourseRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof EditCourseRequest
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditCourseRequest
     */
    'sales_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditCourseRequest
     */
    'max_sales'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditCourseRequest
     */
    'prereleased': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'release_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditCourseRequest
     */
    'has_exam': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'changelog'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditCourseRequest
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditCourseRequest
     */
    'promotion_code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCourseRequest
     */
    'discord_roles': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCourseRequest
     */
    'certified_discord_roles': Array<string>;
}
/**
 * 
 * @export
 * @interface EditCourseResponse
 */
export interface EditCourseResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditCourseResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminCourseSchema}
     * @memberof EditCourseResponse
     */
    'course': AdminCourseSchema;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface EditExamRequest
 */
export interface EditExamRequest {
    /**
     * 
     * @type {string}
     * @memberof EditExamRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EditExamRequest
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof EditExamRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof EditExamRequest
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditExamRequest
     */
    'duration_hours': number;
    /**
     * 
     * @type {string}
     * @memberof EditExamRequest
     */
    'platform': string;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof EditExamRequest
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {boolean}
     * @memberof EditExamRequest
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditExamRequest
     */
    'promotion_code'?: string;
}
/**
 * 
 * @export
 * @interface EditExamResponse
 */
export interface EditExamResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditExamResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminExamSchema}
     * @memberof EditExamResponse
     */
    'exam': AdminExamSchema;
}
/**
 * 
 * @export
 * @interface EditGCPProjectResponse
 */
export interface EditGCPProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditGCPProjectResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminGCPProjectSchema}
     * @memberof EditGCPProjectResponse
     */
    'project'?: AdminGCPProjectSchema;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface EditLabRequest
 */
export interface EditLabRequest {
    /**
     * 
     * @type {string}
     * @memberof EditLabRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EditLabRequest
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof EditLabRequest
     */
    'price15': number;
    /**
     * 
     * @type {number}
     * @memberof EditLabRequest
     */
    'price30': number;
    /**
     * 
     * @type {number}
     * @memberof EditLabRequest
     */
    'price90': number;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof EditLabRequest
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {number}
     * @memberof EditLabRequest
     */
    'promotion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditLabRequest
     */
    'prereleased': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditLabRequest
     */
    'platform': string;
    /**
     * 
     * @type {boolean}
     * @memberof EditLabRequest
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditLabRequest
     */
    'promotion_code'?: string;
}
/**
 * 
 * @export
 * @interface EditLabResponse
 */
export interface EditLabResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditLabResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminLabSchema}
     * @memberof EditLabResponse
     */
    'lab': AdminLabSchema;
}
/**
 * 
 * @export
 * @interface EditUserResponse
 */
export interface EditUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EditUserResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminUserSchema}
     * @memberof EditUserResponse
     */
    'user'?: AdminUserSchema;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const EmailSubscriptionEnum = {
    Required: 'required',
    Commercial: 'commercial',
    Arte: 'arte'
} as const;

export type EmailSubscriptionEnum = typeof EmailSubscriptionEnum[keyof typeof EmailSubscriptionEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ExamTimeEnum = {
    NUMBER_0: 0,
    NUMBER_3: 3,
    NUMBER_6: 6,
    NUMBER_9: 9,
    NUMBER_12: 12,
    NUMBER_15: 15,
    NUMBER_18: 18,
    NUMBER_21: 21
} as const;

export type ExamTimeEnum = typeof ExamTimeEnum[keyof typeof ExamTimeEnum];


/**
 * 
 * @export
 * @interface ExtendLessonLabResponse
 */
export interface ExtendLessonLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof ExtendLessonLabResponse
     */
    'lab_data': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendLessonLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ExtraPointsPRPayload
 */
export interface ExtraPointsPRPayload {
    /**
     * 
     * @type {string}
     * @memberof ExtraPointsPRPayload
     */
    'pr_number': string;
}
/**
 * 
 * @export
 * @interface ExtraPointsPRResponse
 */
export interface ExtraPointsPRResponse {
    /**
     * 
     * @type {UserExamSchema}
     * @memberof ExtraPointsPRResponse
     */
    'user_exam': UserExamSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraPointsPRResponse
     */
    'success': boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FlagType = {
    NoFlag: 'No flag',
    Static: 'Static',
    Dynamic: 'Dynamic'
} as const;

export type FlagType = typeof FlagType[keyof typeof FlagType];


/**
 * 
 * @export
 * @interface ForgotPasswordPayload
 */
export interface ForgotPasswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordPayload
     */
    'captcha_token': string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordResponse
 */
export interface ForgotPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ForgotPasswordResponse
     */
    'success': boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const GCPAccountState = {
    Pending: 'Pending',
    Available: 'Available',
    Locked: 'Locked',
    Provisioning: 'Provisioning',
    Provisioned: 'Provisioned',
    Dirty: 'Dirty',
    Nuking: 'Nuking',
    Nuked: 'Nuked'
} as const;

export type GCPAccountState = typeof GCPAccountState[keyof typeof GCPAccountState];


/**
 * 
 * @export
 * @interface GenerateCertificatePayload
 */
export interface GenerateCertificatePayload {
    /**
     * 
     * @type {string}
     * @memberof GenerateCertificatePayload
     */
    'full_name': string;
}
/**
 * 
 * @export
 * @interface GenerateCertificateResponse
 */
export interface GenerateCertificateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GenerateCertificateResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HealthResponse
 */
export interface HealthResponse {
    /**
     * 
     * @type {boolean}
     * @memberof HealthResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NukeAWSAccountResponse
 */
export interface NukeAWSAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof NukeAWSAccountResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminAWSAccountSchema}
     * @memberof NukeAWSAccountResponse
     */
    'account'?: AdminAWSAccountSchema;
}
/**
 * 
 * @export
 * @interface NukeGCPProjectResponse
 */
export interface NukeGCPProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof NukeGCPProjectResponse
     */
    'success': boolean;
    /**
     * 
     * @type {AdminGCPProjectSchema}
     * @memberof NukeGCPProjectResponse
     */
    'project'?: AdminGCPProjectSchema;
}
/**
 * 
 * @export
 * @interface PasswordResetUserResponse
 */
export interface PasswordResetUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordResetUserResponse
     */
    'success': boolean;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PrivateUserSchema
 */
export interface PrivateUserSchema {
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateUserSchema
     */
    'discord'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrivateUserSchema
     */
    'is_active': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateUserSchema
     */
    'roles': Array<string>;
    /**
     * 
     * @type {Array<EmailSubscriptionEnum>}
     * @memberof PrivateUserSchema
     */
    'email_subscriptions': Array<EmailSubscriptionEnum>;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PrivateVoucherSchema
 */
export interface PrivateVoucherSchema {
    /**
     * 
     * @type {string}
     * @memberof PrivateVoucherSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateVoucherSchema
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateVoucherSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateVoucherSchema
     */
    'redeemed_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrivateVoucherSchema
     */
    'is_used': boolean;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof PrivateVoucherSchema
     */
    'course'?: PublicCourseSchema;
    /**
     * 
     * @type {PublicLabSchema}
     * @memberof PrivateVoucherSchema
     */
    'lab'?: PublicLabSchema;
    /**
     * 
     * @type {PublicExamSchema}
     * @memberof PrivateVoucherSchema
     */
    'exam'?: PublicExamSchema;
}
/**
 * 
 * @export
 * @interface ProfileUpdatePayload
 */
export interface ProfileUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdatePayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdatePayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdatePayload
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdatePayload
     */
    'discord'?: string;
    /**
     * 
     * @type {Array<EmailSubscriptionEnum>}
     * @memberof ProfileUpdatePayload
     */
    'email_subscriptions': Array<EmailSubscriptionEnum>;
}
/**
 * 
 * @export
 * @interface ProfileUpdateResponse
 */
export interface ProfileUpdateResponse {
    /**
     * 
     * @type {PrivateUserSchema}
     * @memberof ProfileUpdateResponse
     */
    'user': PrivateUserSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileUpdateResponse
     */
    'success': boolean;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicCertificateSchema
 */
export interface PublicCertificateSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicCertificateSchema
     */
    'id': string;
    /**
     * 
     * @type {PublicUserSchema}
     * @memberof PublicCertificateSchema
     */
    'user': PublicUserSchema;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof PublicCertificateSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {string}
     * @memberof PublicCertificateSchema
     */
    'awarded_at': string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicCourseSchema
 */
export interface PublicCourseSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'logo': string;
    /**
     * 
     * @type {object}
     * @memberof PublicCourseSchema
     */
    'syllabus'?: object;
    /**
     * 
     * @type {object}
     * @memberof PublicCourseSchema
     */
    'faqs'?: object;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseSchema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseSchema
     */
    'sales_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicCourseSchema
     */
    'max_sales'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCourseSchema
     */
    'prereleased': boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'release_date'?: string;
    /**
     * 
     * @type {Array<PublicSectionSchema>}
     * @memberof PublicCourseSchema
     */
    'sections'?: Array<PublicSectionSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCourseSchema
     */
    'has_exam': boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicCourseSchema
     */
    'changelog'?: string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicExamFlagSchema
 */
export interface PublicExamFlagSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicExamFlagSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicExamFlagSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof PublicExamFlagSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicExamFlagSchema
     */
    'description': string;
    /**
     * 
     * @type {object}
     * @memberof PublicExamFlagSchema
     */
    'tf_outputs': object;
    /**
     * 
     * @type {FlagType}
     * @memberof PublicExamFlagSchema
     */
    'type': FlagType;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicExamSchema
 */
export interface PublicExamSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicExamSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicExamSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicExamSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicExamSchema
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PublicExamSchema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof PublicExamSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicExamSchema
     */
    'duration_hours': number;
    /**
     * 
     * @type {string}
     * @memberof PublicExamSchema
     */
    'platform': string;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof PublicExamSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {Array<PublicExamFlagSchema>}
     * @memberof PublicExamSchema
     */
    'examflags'?: Array<PublicExamFlagSchema>;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicFlagSchema
 */
export interface PublicFlagSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicFlagSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicFlagSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof PublicFlagSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicFlagSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublicFlagSchema
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof PublicFlagSchema
     */
    'tf_outputs': object;
    /**
     * 
     * @type {FlagType}
     * @memberof PublicFlagSchema
     */
    'type': FlagType;
}


/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicLabSchema
 */
export interface PublicLabSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicLabSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLabSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLabSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLabSchema
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PublicLabSchema
     */
    'price15': number;
    /**
     * 
     * @type {number}
     * @memberof PublicLabSchema
     */
    'price30': number;
    /**
     * 
     * @type {number}
     * @memberof PublicLabSchema
     */
    'price90': number;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof PublicLabSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {number}
     * @memberof PublicLabSchema
     */
    'promotion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicLabSchema
     */
    'prereleased': boolean;
    /**
     * 
     * @type {Array<PublicFlagSchema>}
     * @memberof PublicLabSchema
     */
    'flags'?: Array<PublicFlagSchema>;
    /**
     * 
     * @type {string}
     * @memberof PublicLabSchema
     */
    'platform': string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicLessonSchema
 */
export interface PublicLessonSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicLessonSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicLessonSchema
     */
    'references': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'slides': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'video': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicLessonSchema
     */
    'is_lab_deployable': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicLessonSchema
     */
    'extra_tf_variables': Array<string>;
    /**
     * 
     * @type {Array<PublicFlagSchema>}
     * @memberof PublicLessonSchema
     */
    'flags': Array<PublicFlagSchema>;
    /**
     * 
     * @type {string}
     * @memberof PublicLessonSchema
     */
    'created_at': string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicSectionSchema
 */
export interface PublicSectionSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicSectionSchema
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicSectionSchema
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof PublicSectionSchema
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSectionSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSectionSchema
     */
    'description': string;
    /**
     * 
     * @type {Array<PublicLessonSchema>}
     * @memberof PublicSectionSchema
     */
    'lessons': Array<PublicLessonSchema>;
    /**
     * 
     * @type {string}
     * @memberof PublicSectionSchema
     */
    'created_at': string;
}
/**
 * Base Schema class which other schemas can inherit from. This class just enables orm_mode which allows construction from a database model.
 * @export
 * @interface PublicUserSchema
 */
export interface PublicUserSchema {
    /**
     * 
     * @type {string}
     * @memberof PublicUserSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserSchema
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserSchema
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface PurchaseCoursePayload
 */
export interface PurchaseCoursePayload {
    /**
     * 
     * @type {string}
     * @memberof PurchaseCoursePayload
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseCoursePayload
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface PurchaseCourseResponse
 */
export interface PurchaseCourseResponse {
    /**
     * 
     * @type {string}
     * @memberof PurchaseCourseResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface PurchaseExamPayload
 */
export interface PurchaseExamPayload {
    /**
     * 
     * @type {string}
     * @memberof PurchaseExamPayload
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseExamPayload
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface PurchaseExamResponse
 */
export interface PurchaseExamResponse {
    /**
     * 
     * @type {string}
     * @memberof PurchaseExamResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface PurchaseLabPayload
 */
export interface PurchaseLabPayload {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLabPayload
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLabPayload
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLabPayload
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface PurchaseLabResponse
 */
export interface PurchaseLabResponse {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLabResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ReSendCertificateResponse
 */
export interface ReSendCertificateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ReSendCertificateResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ReSendVoucherPayload
 */
export interface ReSendVoucherPayload {
    /**
     * 
     * @type {string}
     * @memberof ReSendVoucherPayload
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ReSendVoucherResponse
 */
export interface ReSendVoucherResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ReSendVoucherResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface RemoveUserFromCourseResponse
 */
export interface RemoveUserFromCourseResponse {
    /**
     * 
     * @type {Array<AdminUserCourseSchema>}
     * @memberof RemoveUserFromCourseResponse
     */
    'user_course_associations': Array<AdminUserCourseSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveUserFromCourseResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface RescheduleExamPayload
 */
export interface RescheduleExamPayload {
    /**
     * 
     * @type {string}
     * @memberof RescheduleExamPayload
     */
    'date': string;
    /**
     * 
     * @type {ExamTimeEnum}
     * @memberof RescheduleExamPayload
     */
    'time': ExamTimeEnum;
}


/**
 * 
 * @export
 * @interface RescheduleExamResponse
 */
export interface RescheduleExamResponse {
    /**
     * 
     * @type {UserExamSchema}
     * @memberof RescheduleExamResponse
     */
    'user_exam': UserExamSchema;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleExamResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResendActivationResponse
 */
export interface ResendActivationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ResendActivationResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResetExamResponse
 */
export interface ResetExamResponse {
    /**
     * 
     * @type {UserExamSchema}
     * @memberof ResetExamResponse
     */
    'data': UserExamSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ResetExamResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResetLabResponse
 */
export interface ResetLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof ResetLabResponse
     */
    'data': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ResetLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResetLessonLabResponse
 */
export interface ResetLessonLabResponse {
    /**
     * 
     * @type {UserLabSchema}
     * @memberof ResetLessonLabResponse
     */
    'lab_data': UserLabSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ResetLessonLabResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResetPasswordPayload
 */
export interface ResetPasswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordPayload
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordPayload
     */
    'match_password': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ScheduleExamPayload
 */
export interface ScheduleExamPayload {
    /**
     * 
     * @type {string}
     * @memberof ScheduleExamPayload
     */
    'date': string;
    /**
     * 
     * @type {ExamTimeEnum}
     * @memberof ScheduleExamPayload
     */
    'time': ExamTimeEnum;
}


/**
 * 
 * @export
 * @interface ScheduleExamResponse
 */
export interface ScheduleExamResponse {
    /**
     * 
     * @type {UserExamSchema}
     * @memberof ScheduleExamResponse
     */
    'user_exam': UserExamSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleExamResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SignInResponse
 */
export interface SignInResponse {
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    'token_type': string;
    /**
     * 
     * @type {PrivateUserSchema}
     * @memberof SignInResponse
     */
    'user': PrivateUserSchema;
}
/**
 * 
 * @export
 * @interface SignOutResponse
 */
export interface SignOutResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SignOutResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SignUpPayload
 */
export interface SignUpPayload {
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'match_password': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpPayload
     */
    'terms': boolean;
    /**
     * 
     * @type {Array<EmailSubscriptionEnum>}
     * @memberof SignUpPayload
     */
    'email_subscriptions': Array<EmailSubscriptionEnum>;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpPayload
     */
    'captcha_token': string;
}
/**
 * 
 * @export
 * @interface SignUpResponse
 */
export interface SignUpResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SignUpResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SubmitExamFlagPayload
 */
export interface SubmitExamFlagPayload {
    /**
     * 
     * @type {string}
     * @memberof SubmitExamFlagPayload
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SubmitExamFlagResponse
 */
export interface SubmitExamFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SubmitExamFlagResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SubmitFlagPayload
 */
export interface SubmitFlagPayload {
    /**
     * 
     * @type {string}
     * @memberof SubmitFlagPayload
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SubmitFlagResponse
 */
export interface SubmitFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SubmitFlagResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SubtitleURL
 */
export interface SubtitleURL {
    /**
     * 
     * @type {string}
     * @memberof SubtitleURL
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof SubtitleURL
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UpdateLastSeenPayload
 */
export interface UpdateLastSeenPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateLastSeenPayload
     */
    'section': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLastSeenPayload
     */
    'lesson': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLastSeenPayload
     */
    'second': number;
}
/**
 * 
 * @export
 * @interface UserCourseSchema
 */
export interface UserCourseSchema {
    /**
     * 
     * @type {object}
     * @memberof UserCourseSchema
     */
    'data': object;
    /**
     * 
     * @type {PrivateUserSchema}
     * @memberof UserCourseSchema
     */
    'user': PrivateUserSchema;
    /**
     * 
     * @type {PublicCourseSchema}
     * @memberof UserCourseSchema
     */
    'course': PublicCourseSchema;
    /**
     * 
     * @type {string}
     * @memberof UserCourseSchema
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface UserExamSchema
 */
export interface UserExamSchema {
    /**
     * 
     * @type {object}
     * @memberof UserExamSchema
     */
    'data'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserExamSchema
     */
    'active_until': string;
    /**
     * 
     * @type {string}
     * @memberof UserExamSchema
     */
    'exam_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExamSchema
     */
    'exam_end'?: string;
    /**
     * 
     * @type {PrivateUserSchema}
     * @memberof UserExamSchema
     */
    'user': PrivateUserSchema;
    /**
     * 
     * @type {PublicExamSchema}
     * @memberof UserExamSchema
     */
    'exam': PublicExamSchema;
    /**
     * 
     * @type {string}
     * @memberof UserExamSchema
     */
    'created_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserExamSchema
     */
    'last_resets': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserExamSchema
     */
    'extra_points_pr'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserExamSchema
     */
    'reschedules': Array<string>;
}
/**
 * 
 * @export
 * @interface UserLabSchema
 */
export interface UserLabSchema {
    /**
     * 
     * @type {object}
     * @memberof UserLabSchema
     */
    'data'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserLabSchema
     */
    'active_until': string;
    /**
     * 
     * @type {PrivateUserSchema}
     * @memberof UserLabSchema
     */
    'user': PrivateUserSchema;
    /**
     * 
     * @type {PublicLabSchema}
     * @memberof UserLabSchema
     */
    'lab': PublicLabSchema;
    /**
     * 
     * @type {string}
     * @memberof UserLabSchema
     */
    'created_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLabSchema
     */
    'last_resets': Array<string>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface VideoPresignedURLResponse
 */
export interface VideoPresignedURLResponse {
    /**
     * 
     * @type {string}
     * @memberof VideoPresignedURLResponse
     */
    'presigned_url': string;
    /**
     * 
     * @type {Array<SubtitleURL>}
     * @memberof VideoPresignedURLResponse
     */
    'subtitles_urls': Array<SubtitleURL>;
}
/**
 * 
 * @export
 * @interface WebhookCheckoutCompleteResponse
 */
export interface WebhookCheckoutCompleteResponse {
    /**
     * 
     * @type {any}
     * @memberof WebhookCheckoutCompleteResponse
     */
    'data'?: any;
    /**
     * 
     * @type {Error}
     * @memberof WebhookCheckoutCompleteResponse
     */
    'error'?: Error;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create course voucher
         * @summary Create Course Voucher
         * @param {CreateCourseVoucherRequest} createCourseVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseVoucher: async (createCourseVoucherRequest: CreateCourseVoucherRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCourseVoucherRequest' is not null or undefined
            assertParamExists('adminCreateCourseVoucher', 'createCourseVoucherRequest', createCourseVoucherRequest)
            const localVarPath = `/api/v1/admin/course_voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCourseVoucherRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create exam voucher
         * @summary Create Exam Voucher
         * @param {CreateExamVoucherRequest} createExamVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateExamVoucher: async (createExamVoucherRequest: CreateExamVoucherRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExamVoucherRequest' is not null or undefined
            assertParamExists('adminCreateExamVoucher', 'createExamVoucherRequest', createExamVoucherRequest)
            const localVarPath = `/api/v1/admin/exam_voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExamVoucherRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create lab voucher
         * @summary Create Lab Voucher
         * @param {CreateLabVoucherRequest} createLabVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateLabVoucher: async (createLabVoucherRequest: CreateLabVoucherRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLabVoucherRequest' is not null or undefined
            assertParamExists('adminCreateLabVoucher', 'createLabVoucherRequest', createLabVoucherRequest)
            const localVarPath = `/api/v1/admin/lab_voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLabVoucherRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the aws accounts
         * @summary Delete Aws Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAwsAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('adminDeleteAwsAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/admin/aws_accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the certificate
         * @summary Delete Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCertificate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDeleteCertificate', 'id', id)
            const localVarPath = `/api/v1/admin/certificate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the course
         * @summary Delete Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourse: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminDeleteCourse', 'slug', slug)
            const localVarPath = `/api/v1/admin/courses/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the exam
         * @summary Delete Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteExam: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminDeleteExam', 'slug', slug)
            const localVarPath = `/api/v1/admin/exams/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the gcp project
         * @summary Delete Gcp Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteGcpProject: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('adminDeleteGcpProject', 'projectId', projectId)
            const localVarPath = `/api/v1/admin/gcp_projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the lab
         * @summary Delete Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteLab: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminDeleteLab', 'slug', slug)
            const localVarPath = `/api/v1/admin/labs/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user
         * @summary Delete User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUser: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('adminDeleteUser', 'username', username)
            const localVarPath = `/api/v1/admin/user/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit the aws accounts
         * @summary Edit Aws Accounts
         * @param {AdminAWSAccountSchema} adminAWSAccountSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditAwsAccounts: async (adminAWSAccountSchema: AdminAWSAccountSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminAWSAccountSchema' is not null or undefined
            assertParamExists('adminEditAwsAccounts', 'adminAWSAccountSchema', adminAWSAccountSchema)
            const localVarPath = `/api/v1/admin/aws_account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAWSAccountSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit the certificate
         * @summary Edit Certificate
         * @param {AdminCertificateSchema} adminCertificateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCertificate: async (adminCertificateSchema: AdminCertificateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCertificateSchema' is not null or undefined
            assertParamExists('adminEditCertificate', 'adminCertificateSchema', adminCertificateSchema)
            const localVarPath = `/api/v1/admin/certificate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCertificateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update course
         * @summary Edit Course
         * @param {string} slug 
         * @param {EditCourseRequest} editCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCourse: async (slug: string, editCourseRequest: EditCourseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditCourse', 'slug', slug)
            // verify required parameter 'editCourseRequest' is not null or undefined
            assertParamExists('adminEditCourse', 'editCourseRequest', editCourseRequest)
            const localVarPath = `/api/v1/admin/courses/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update exam
         * @summary Edit Exam
         * @param {string} slug 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditExam: async (slug: string, editExamRequest: EditExamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditExam', 'slug', slug)
            // verify required parameter 'editExamRequest' is not null or undefined
            assertParamExists('adminEditExam', 'editExamRequest', editExamRequest)
            const localVarPath = `/api/v1/admin/exams/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editExamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update exam examflags
         * @summary Edit Examflags Json
         * @param {string} slug 
         * @param {Array<AdminExamFlagSchema>} adminExamFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditExamflagsJson: async (slug: string, adminExamFlagSchema: Array<AdminExamFlagSchema>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditExamflagsJson', 'slug', slug)
            // verify required parameter 'adminExamFlagSchema' is not null or undefined
            assertParamExists('adminEditExamflagsJson', 'adminExamFlagSchema', adminExamFlagSchema)
            const localVarPath = `/api/v1/admin/exams/{slug}/examflags`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExamFlagSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update lab flags
         * @summary Edit Flags Json
         * @param {string} slug 
         * @param {Array<AdminFlagSchema>} adminFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditFlagsJson: async (slug: string, adminFlagSchema: Array<AdminFlagSchema>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditFlagsJson', 'slug', slug)
            // verify required parameter 'adminFlagSchema' is not null or undefined
            assertParamExists('adminEditFlagsJson', 'adminFlagSchema', adminFlagSchema)
            const localVarPath = `/api/v1/admin/labs/{slug}/flags`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFlagSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit the gcp projects
         * @summary Edit Gcp Projects
         * @param {AdminGCPProjectSchema} adminGCPProjectSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditGcpProjects: async (adminGCPProjectSchema: AdminGCPProjectSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGCPProjectSchema' is not null or undefined
            assertParamExists('adminEditGcpProjects', 'adminGCPProjectSchema', adminGCPProjectSchema)
            const localVarPath = `/api/v1/admin/gcp_project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGCPProjectSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update lab
         * @summary Edit Lab
         * @param {string} slug 
         * @param {EditLabRequest} editLabRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditLab: async (slug: string, editLabRequest: EditLabRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditLab', 'slug', slug)
            // verify required parameter 'editLabRequest' is not null or undefined
            assertParamExists('adminEditLab', 'editLabRequest', editLabRequest)
            const localVarPath = `/api/v1/admin/labs/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editLabRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update course sections
         * @summary Edit Sections Json
         * @param {string} slug 
         * @param {Array<AdminSectionSchema>} adminSectionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditSectionsJson: async (slug: string, adminSectionSchema: Array<AdminSectionSchema>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminEditSectionsJson', 'slug', slug)
            // verify required parameter 'adminSectionSchema' is not null or undefined
            assertParamExists('adminEditSectionsJson', 'adminSectionSchema', adminSectionSchema)
            const localVarPath = `/api/v1/admin/courses/{slug}/sections`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSectionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit the user
         * @summary Edit User
         * @param {AdminUserSchema} adminUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditUser: async (adminUserSchema: AdminUserSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserSchema' is not null or undefined
            assertParamExists('adminEditUser', 'adminUserSchema', adminUserSchema)
            const localVarPath = `/api/v1/admin/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Nuke the aws accounts
         * @summary Nuke Aws Accounts
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNukeAwsAccounts: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('adminNukeAwsAccounts', 'accountId', accountId)
            const localVarPath = `/api/v1/admin/nuke_aws_accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Nuke the gcp projects
         * @summary Nuke Gcp Projects
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNukeGcpProjects: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('adminNukeGcpProjects', 'projectId', projectId)
            const localVarPath = `/api/v1/admin/nuke_gcp_projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send password reset the user
         * @summary Password Reset User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPasswordResetUser: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('adminPasswordResetUser', 'username', username)
            const localVarPath = `/api/v1/admin/user/forgot_password/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all aws accounts
         * @summary Read Aws Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadAwsAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/aws_accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all certificates
         * @summary Read Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCertificates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get course
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourse: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminReadCourse', 'slug', slug)
            const localVarPath = `/api/v1/admin/courses/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users in course
         * @summary Read Course Users
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourseUsers: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminReadCourseUsers', 'slug', slug)
            const localVarPath = `/api/v1/admin/courses/{slug}/users`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all course vouchers
         * @summary Read Course Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourseVouchers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/course_vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all courses
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all counts
         * @summary Read Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get exam
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExam: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminReadExam', 'slug', slug)
            const localVarPath = `/api/v1/admin/exams/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all exams
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExams: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all exam vouchers
         * @summary Read  Examvouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExamvouchers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/exam_vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all gcp projects
         * @summary Read Gcp Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadGcpProjects: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/gcp_projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get lab
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLab: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminReadLab', 'slug', slug)
            const localVarPath = `/api/v1/admin/labs/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all lab vouchers
         * @summary Read Lab Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLabVouchers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/lab_vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all labs
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLabs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/labs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all users
         * @summary Read Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove users from course
         * @summary Remove User From Course
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveUserFromCourse: async (slug: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminRemoveUserFromCourse', 'slug', slug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminRemoveUserFromCourse', 'id', id)
            const localVarPath = `/api/v1/admin/courses/{slug}/users/{id}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send activation email to the user
         * @summary Resend Activate Email
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendActivateEmail: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('adminResendActivateEmail', 'username', username)
            const localVarPath = `/api/v1/admin/user/resend_activate_email/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetLab: async (slug: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('adminResetLab', 'slug', slug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminResetLab', 'id', id)
            const localVarPath = `/api/v1/admin/labs/reset/{slug}/{id}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Create course voucher
         * @summary Create Course Voucher
         * @param {CreateCourseVoucherRequest} createCourseVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateCourseVoucher(createCourseVoucherRequest: CreateCourseVoucherRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCourseVoucherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateCourseVoucher(createCourseVoucherRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCreateCourseVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create exam voucher
         * @summary Create Exam Voucher
         * @param {CreateExamVoucherRequest} createExamVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateExamVoucher(createExamVoucherRequest: CreateExamVoucherRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExamVoucherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateExamVoucher(createExamVoucherRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCreateExamVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create lab voucher
         * @summary Create Lab Voucher
         * @param {CreateLabVoucherRequest} createLabVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateLabVoucher(createLabVoucherRequest: CreateLabVoucherRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateLabVoucherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateLabVoucher(createLabVoucherRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCreateLabVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the aws accounts
         * @summary Delete Aws Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteAwsAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAWSAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteAwsAccount(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteAwsAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the certificate
         * @summary Delete Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCertificate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCertificate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the course
         * @summary Delete Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteCourse(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteCourse(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the exam
         * @summary Delete Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteExam(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteExam(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the gcp project
         * @summary Delete Gcp Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteGcpProject(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteGCPProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteGcpProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteGcpProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the lab
         * @summary Delete Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteLab(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteLab(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the user
         * @summary Delete User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteUser(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteUser(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit the aws accounts
         * @summary Edit Aws Accounts
         * @param {AdminAWSAccountSchema} adminAWSAccountSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditAwsAccounts(adminAWSAccountSchema: AdminAWSAccountSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditAWSAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditAwsAccounts(adminAWSAccountSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditAwsAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit the certificate
         * @summary Edit Certificate
         * @param {AdminCertificateSchema} adminCertificateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditCertificate(adminCertificateSchema: AdminCertificateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditCertificate(adminCertificateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update course
         * @summary Edit Course
         * @param {string} slug 
         * @param {EditCourseRequest} editCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditCourse(slug: string, editCourseRequest: EditCourseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditCourse(slug, editCourseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update exam
         * @summary Edit Exam
         * @param {string} slug 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditExam(slug: string, editExamRequest: EditExamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditExam(slug, editExamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update exam examflags
         * @summary Edit Examflags Json
         * @param {string} slug 
         * @param {Array<AdminExamFlagSchema>} adminExamFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditExamflagsJson(slug: string, adminExamFlagSchema: Array<AdminExamFlagSchema>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminExamFlagSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditExamflagsJson(slug, adminExamFlagSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditExamflagsJson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update lab flags
         * @summary Edit Flags Json
         * @param {string} slug 
         * @param {Array<AdminFlagSchema>} adminFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditFlagsJson(slug: string, adminFlagSchema: Array<AdminFlagSchema>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminFlagSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditFlagsJson(slug, adminFlagSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditFlagsJson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit the gcp projects
         * @summary Edit Gcp Projects
         * @param {AdminGCPProjectSchema} adminGCPProjectSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditGcpProjects(adminGCPProjectSchema: AdminGCPProjectSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditGCPProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditGcpProjects(adminGCPProjectSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditGcpProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update lab
         * @summary Edit Lab
         * @param {string} slug 
         * @param {EditLabRequest} editLabRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditLab(slug: string, editLabRequest: EditLabRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditLab(slug, editLabRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update course sections
         * @summary Edit Sections Json
         * @param {string} slug 
         * @param {Array<AdminSectionSchema>} adminSectionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditSectionsJson(slug: string, adminSectionSchema: Array<AdminSectionSchema>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSectionSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditSectionsJson(slug, adminSectionSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditSectionsJson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit the user
         * @summary Edit User
         * @param {AdminUserSchema} adminUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditUser(adminUserSchema: AdminUserSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditUser(adminUserSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminEditUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Nuke the aws accounts
         * @summary Nuke Aws Accounts
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNukeAwsAccounts(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NukeAWSAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNukeAwsAccounts(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminNukeAwsAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Nuke the gcp projects
         * @summary Nuke Gcp Projects
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNukeGcpProjects(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NukeGCPProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNukeGcpProjects(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminNukeGcpProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send password reset the user
         * @summary Password Reset User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPasswordResetUser(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPasswordResetUser(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPasswordResetUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all aws accounts
         * @summary Read Aws Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadAwsAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminAWSAccountSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadAwsAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadAwsAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all certificates
         * @summary Read Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadCertificates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminCertificateSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadCertificates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get course
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadCourse(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCourseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadCourse(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users in course
         * @summary Read Course Users
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadCourseUsers(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminUserCourseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadCourseUsers(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadCourseUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all course vouchers
         * @summary Read Course Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadCourseVouchers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminVoucherSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadCourseVouchers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadCourseVouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all courses
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadCourses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminCourseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadCourses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all counts
         * @summary Read Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDashboardData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get exam
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadExam(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminExamSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadExam(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all exams
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadExams(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminExamSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadExams(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadExams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all exam vouchers
         * @summary Read  Examvouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadExamvouchers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminVoucherSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadExamvouchers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadExamvouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all gcp projects
         * @summary Read Gcp Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadGcpProjects(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminGCPProjectSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadGcpProjects(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadGcpProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get lab
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadLab(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLabSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadLab(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all lab vouchers
         * @summary Read Lab Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadLabVouchers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminVoucherSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadLabVouchers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadLabVouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all labs
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadLabs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminLabSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadLabs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadLabs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all users
         * @summary Read Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReadUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminUserSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReadUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReadUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove users from course
         * @summary Remove User From Course
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveUserFromCourse(slug: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveUserFromCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveUserFromCourse(slug, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminRemoveUserFromCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send activation email to the user
         * @summary Resend Activate Email
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResendActivateEmail(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateEmailUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResendActivateEmail(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminResendActivateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResetLab(slug: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResetLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResetLab(slug, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminResetLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Create course voucher
         * @summary Create Course Voucher
         * @param {CreateCourseVoucherRequest} createCourseVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseVoucher(createCourseVoucherRequest: CreateCourseVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateCourseVoucherResponse> {
            return localVarFp.adminCreateCourseVoucher(createCourseVoucherRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create exam voucher
         * @summary Create Exam Voucher
         * @param {CreateExamVoucherRequest} createExamVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateExamVoucher(createExamVoucherRequest: CreateExamVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateExamVoucherResponse> {
            return localVarFp.adminCreateExamVoucher(createExamVoucherRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create lab voucher
         * @summary Create Lab Voucher
         * @param {CreateLabVoucherRequest} createLabVoucherRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateLabVoucher(createLabVoucherRequest: CreateLabVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateLabVoucherResponse> {
            return localVarFp.adminCreateLabVoucher(createLabVoucherRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the aws accounts
         * @summary Delete Aws Account
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteAwsAccount(accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAWSAccountResponse> {
            return localVarFp.adminDeleteAwsAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the certificate
         * @summary Delete Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCertificate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteCertificateResponse> {
            return localVarFp.adminDeleteCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the course
         * @summary Delete Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourse(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteCourseResponse> {
            return localVarFp.adminDeleteCourse(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the exam
         * @summary Delete Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteExam(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteExamResponse> {
            return localVarFp.adminDeleteExam(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the gcp project
         * @summary Delete Gcp Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteGcpProject(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteGCPProjectResponse> {
            return localVarFp.adminDeleteGcpProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the lab
         * @summary Delete Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteLab(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteLabResponse> {
            return localVarFp.adminDeleteLab(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user
         * @summary Delete User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUser(username: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteUserResponse> {
            return localVarFp.adminDeleteUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit the aws accounts
         * @summary Edit Aws Accounts
         * @param {AdminAWSAccountSchema} adminAWSAccountSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditAwsAccounts(adminAWSAccountSchema: AdminAWSAccountSchema, options?: RawAxiosRequestConfig): AxiosPromise<EditAWSAccountResponse> {
            return localVarFp.adminEditAwsAccounts(adminAWSAccountSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit the certificate
         * @summary Edit Certificate
         * @param {AdminCertificateSchema} adminCertificateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCertificate(adminCertificateSchema: AdminCertificateSchema, options?: RawAxiosRequestConfig): AxiosPromise<EditCertificateResponse> {
            return localVarFp.adminEditCertificate(adminCertificateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Update course
         * @summary Edit Course
         * @param {string} slug 
         * @param {EditCourseRequest} editCourseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditCourse(slug: string, editCourseRequest: EditCourseRequest, options?: RawAxiosRequestConfig): AxiosPromise<EditCourseResponse> {
            return localVarFp.adminEditCourse(slug, editCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update exam
         * @summary Edit Exam
         * @param {string} slug 
         * @param {EditExamRequest} editExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditExam(slug: string, editExamRequest: EditExamRequest, options?: RawAxiosRequestConfig): AxiosPromise<EditExamResponse> {
            return localVarFp.adminEditExam(slug, editExamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update exam examflags
         * @summary Edit Examflags Json
         * @param {string} slug 
         * @param {Array<AdminExamFlagSchema>} adminExamFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditExamflagsJson(slug: string, adminExamFlagSchema: Array<AdminExamFlagSchema>, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminExamFlagSchema>> {
            return localVarFp.adminEditExamflagsJson(slug, adminExamFlagSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Update lab flags
         * @summary Edit Flags Json
         * @param {string} slug 
         * @param {Array<AdminFlagSchema>} adminFlagSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditFlagsJson(slug: string, adminFlagSchema: Array<AdminFlagSchema>, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminFlagSchema>> {
            return localVarFp.adminEditFlagsJson(slug, adminFlagSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit the gcp projects
         * @summary Edit Gcp Projects
         * @param {AdminGCPProjectSchema} adminGCPProjectSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditGcpProjects(adminGCPProjectSchema: AdminGCPProjectSchema, options?: RawAxiosRequestConfig): AxiosPromise<EditGCPProjectResponse> {
            return localVarFp.adminEditGcpProjects(adminGCPProjectSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Update lab
         * @summary Edit Lab
         * @param {string} slug 
         * @param {EditLabRequest} editLabRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditLab(slug: string, editLabRequest: EditLabRequest, options?: RawAxiosRequestConfig): AxiosPromise<EditLabResponse> {
            return localVarFp.adminEditLab(slug, editLabRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update course sections
         * @summary Edit Sections Json
         * @param {string} slug 
         * @param {Array<AdminSectionSchema>} adminSectionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditSectionsJson(slug: string, adminSectionSchema: Array<AdminSectionSchema>, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminSectionSchema>> {
            return localVarFp.adminEditSectionsJson(slug, adminSectionSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit the user
         * @summary Edit User
         * @param {AdminUserSchema} adminUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditUser(adminUserSchema: AdminUserSchema, options?: RawAxiosRequestConfig): AxiosPromise<EditUserResponse> {
            return localVarFp.adminEditUser(adminUserSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Nuke the aws accounts
         * @summary Nuke Aws Accounts
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNukeAwsAccounts(accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<NukeAWSAccountResponse> {
            return localVarFp.adminNukeAwsAccounts(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Nuke the gcp projects
         * @summary Nuke Gcp Projects
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNukeGcpProjects(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<NukeGCPProjectResponse> {
            return localVarFp.adminNukeGcpProjects(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Send password reset the user
         * @summary Password Reset User
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPasswordResetUser(username: string, options?: RawAxiosRequestConfig): AxiosPromise<PasswordResetUserResponse> {
            return localVarFp.adminPasswordResetUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * List all aws accounts
         * @summary Read Aws Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadAwsAccounts(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminAWSAccountSchema>> {
            return localVarFp.adminReadAwsAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * List all certificates
         * @summary Read Certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCertificates(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCertificateSchema>> {
            return localVarFp.adminReadCertificates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get course
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourse(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminCourseSchema> {
            return localVarFp.adminReadCourse(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users in course
         * @summary Read Course Users
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourseUsers(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminUserCourseSchema>> {
            return localVarFp.adminReadCourseUsers(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * List all course vouchers
         * @summary Read Course Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourseVouchers(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminVoucherSchema>> {
            return localVarFp.adminReadCourseVouchers(options).then((request) => request(axios, basePath));
        },
        /**
         * List all courses
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadCourses(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCourseSchema>> {
            return localVarFp.adminReadCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * List all counts
         * @summary Read Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadDashboard(options?: RawAxiosRequestConfig): AxiosPromise<AdminDashboardData> {
            return localVarFp.adminReadDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * Get exam
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExam(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminExamSchema> {
            return localVarFp.adminReadExam(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * List all exams
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExams(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminExamSchema>> {
            return localVarFp.adminReadExams(options).then((request) => request(axios, basePath));
        },
        /**
         * List all exam vouchers
         * @summary Read  Examvouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadExamvouchers(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminVoucherSchema>> {
            return localVarFp.adminReadExamvouchers(options).then((request) => request(axios, basePath));
        },
        /**
         * List all gcp projects
         * @summary Read Gcp Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadGcpProjects(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminGCPProjectSchema>> {
            return localVarFp.adminReadGcpProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Get lab
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLab(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminLabSchema> {
            return localVarFp.adminReadLab(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * List all lab vouchers
         * @summary Read Lab Vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLabVouchers(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminVoucherSchema>> {
            return localVarFp.adminReadLabVouchers(options).then((request) => request(axios, basePath));
        },
        /**
         * List all labs
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadLabs(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminLabSchema>> {
            return localVarFp.adminReadLabs(options).then((request) => request(axios, basePath));
        },
        /**
         * List all users
         * @summary Read Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReadUsers(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminUserSchema>> {
            return localVarFp.adminReadUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Remove users from course
         * @summary Remove User From Course
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveUserFromCourse(slug: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<RemoveUserFromCourseResponse> {
            return localVarFp.adminRemoveUserFromCourse(slug, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Send activation email to the user
         * @summary Resend Activate Email
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResendActivateEmail(username: string, options?: RawAxiosRequestConfig): AxiosPromise<ActivateEmailUserResponse> {
            return localVarFp.adminResendActivateEmail(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetLab(slug: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminResetLabResponse> {
            return localVarFp.adminResetLab(slug, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Create course voucher
     * @summary Create Course Voucher
     * @param {CreateCourseVoucherRequest} createCourseVoucherRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateCourseVoucher(createCourseVoucherRequest: CreateCourseVoucherRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCreateCourseVoucher(createCourseVoucherRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create exam voucher
     * @summary Create Exam Voucher
     * @param {CreateExamVoucherRequest} createExamVoucherRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateExamVoucher(createExamVoucherRequest: CreateExamVoucherRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCreateExamVoucher(createExamVoucherRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create lab voucher
     * @summary Create Lab Voucher
     * @param {CreateLabVoucherRequest} createLabVoucherRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCreateLabVoucher(createLabVoucherRequest: CreateLabVoucherRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCreateLabVoucher(createLabVoucherRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the aws accounts
     * @summary Delete Aws Account
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteAwsAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteAwsAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the certificate
     * @summary Delete Certificate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCertificate(id: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the course
     * @summary Delete Course
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteCourse(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteCourse(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the exam
     * @summary Delete Exam
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteExam(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteExam(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the gcp project
     * @summary Delete Gcp Project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteGcpProject(projectId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteGcpProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the lab
     * @summary Delete Lab
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteLab(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteLab(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user
     * @summary Delete User
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeleteUser(username: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeleteUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit the aws accounts
     * @summary Edit Aws Accounts
     * @param {AdminAWSAccountSchema} adminAWSAccountSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditAwsAccounts(adminAWSAccountSchema: AdminAWSAccountSchema, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditAwsAccounts(adminAWSAccountSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit the certificate
     * @summary Edit Certificate
     * @param {AdminCertificateSchema} adminCertificateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditCertificate(adminCertificateSchema: AdminCertificateSchema, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditCertificate(adminCertificateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update course
     * @summary Edit Course
     * @param {string} slug 
     * @param {EditCourseRequest} editCourseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditCourse(slug: string, editCourseRequest: EditCourseRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditCourse(slug, editCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update exam
     * @summary Edit Exam
     * @param {string} slug 
     * @param {EditExamRequest} editExamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditExam(slug: string, editExamRequest: EditExamRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditExam(slug, editExamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update exam examflags
     * @summary Edit Examflags Json
     * @param {string} slug 
     * @param {Array<AdminExamFlagSchema>} adminExamFlagSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditExamflagsJson(slug: string, adminExamFlagSchema: Array<AdminExamFlagSchema>, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditExamflagsJson(slug, adminExamFlagSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update lab flags
     * @summary Edit Flags Json
     * @param {string} slug 
     * @param {Array<AdminFlagSchema>} adminFlagSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditFlagsJson(slug: string, adminFlagSchema: Array<AdminFlagSchema>, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditFlagsJson(slug, adminFlagSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit the gcp projects
     * @summary Edit Gcp Projects
     * @param {AdminGCPProjectSchema} adminGCPProjectSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditGcpProjects(adminGCPProjectSchema: AdminGCPProjectSchema, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditGcpProjects(adminGCPProjectSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update lab
     * @summary Edit Lab
     * @param {string} slug 
     * @param {EditLabRequest} editLabRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditLab(slug: string, editLabRequest: EditLabRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditLab(slug, editLabRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update course sections
     * @summary Edit Sections Json
     * @param {string} slug 
     * @param {Array<AdminSectionSchema>} adminSectionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditSectionsJson(slug: string, adminSectionSchema: Array<AdminSectionSchema>, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditSectionsJson(slug, adminSectionSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit the user
     * @summary Edit User
     * @param {AdminUserSchema} adminUserSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminEditUser(adminUserSchema: AdminUserSchema, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminEditUser(adminUserSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Nuke the aws accounts
     * @summary Nuke Aws Accounts
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminNukeAwsAccounts(accountId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminNukeAwsAccounts(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Nuke the gcp projects
     * @summary Nuke Gcp Projects
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminNukeGcpProjects(projectId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminNukeGcpProjects(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send password reset the user
     * @summary Password Reset User
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPasswordResetUser(username: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPasswordResetUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all aws accounts
     * @summary Read Aws Accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadAwsAccounts(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadAwsAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all certificates
     * @summary Read Certificates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadCertificates(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadCertificates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get course
     * @summary Read Course
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadCourse(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadCourse(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users in course
     * @summary Read Course Users
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadCourseUsers(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadCourseUsers(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all course vouchers
     * @summary Read Course Vouchers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadCourseVouchers(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadCourseVouchers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all courses
     * @summary Read Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadCourses(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all counts
     * @summary Read Dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadDashboard(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get exam
     * @summary Read Exam
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadExam(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadExam(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all exams
     * @summary Read Exams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadExams(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadExams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all exam vouchers
     * @summary Read  Examvouchers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadExamvouchers(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadExamvouchers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all gcp projects
     * @summary Read Gcp Projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadGcpProjects(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadGcpProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get lab
     * @summary Read Lab
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadLab(slug: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadLab(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all lab vouchers
     * @summary Read Lab Vouchers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadLabVouchers(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadLabVouchers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all labs
     * @summary Read Labs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadLabs(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadLabs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all users
     * @summary Read Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReadUsers(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReadUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove users from course
     * @summary Remove User From Course
     * @param {string} slug 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminRemoveUserFromCourse(slug: string, id: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminRemoveUserFromCourse(slug, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send activation email to the user
     * @summary Resend Activate Email
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminResendActivateEmail(username: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminResendActivateEmail(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset lab
     * @summary Reset Lab
     * @param {string} slug 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminResetLab(slug: string, id: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminResetLab(slug, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate Account
         * @param {ActivateAccountPayload} activateAccountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authActivateAccount: async (activateAccountPayload: ActivateAccountPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateAccountPayload' is not null or undefined
            assertParamExists('authActivateAccount', 'activateAccountPayload', activateAccountPayload)
            const localVarPath = `/api/v1/auth/activate_account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateAccountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forgot Password
         * @param {ForgotPasswordPayload} forgotPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPassword: async (forgotPasswordPayload: ForgotPasswordPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordPayload' is not null or undefined
            assertParamExists('authForgotPassword', 'forgotPasswordPayload', forgotPasswordPayload)
            const localVarPath = `/api/v1/auth/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh: async (refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend Activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResendActivation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/resend_activation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password
         * @param {ResetPasswordPayload} resetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword: async (resetPasswordPayload: ResetPasswordPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordPayload' is not null or undefined
            assertParamExists('authResetPassword', 'resetPasswordPayload', resetPasswordPayload)
            const localVarPath = `/api/v1/auth/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth2 compatible login, get an access token for future requests
         * @summary Signin
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignin: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('authSignin', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('authSignin', 'password', password)
            const localVarPath = `/api/v1/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signout
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignout: async (refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signup
         * @param {SignUpPayload} signUpPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup: async (signUpPayload: SignUpPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpPayload' is not null or undefined
            assertParamExists('authSignup', 'signUpPayload', signUpPayload)
            const localVarPath = `/api/v1/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate Account
         * @param {ActivateAccountPayload} activateAccountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authActivateAccount(activateAccountPayload: ActivateAccountPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authActivateAccount(activateAccountPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authActivateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Forgot Password
         * @param {ForgotPasswordPayload} forgotPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authForgotPassword(forgotPasswordPayload: ForgotPasswordPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgotPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authForgotPassword(forgotPasswordPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authForgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefresh(refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefresh(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend Activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authResendActivation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResendActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authResendActivation(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authResendActivation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password
         * @param {ResetPasswordPayload} resetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authResetPassword(resetPasswordPayload: ResetPasswordPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authResetPassword(resetPasswordPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth2 compatible login, get an access token for future requests
         * @summary Signin
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignin(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignin(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSignin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Signout
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignout(refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignOutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignout(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSignout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Signup
         * @param {SignUpPayload} signUpPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignup(signUpPayload: SignUpPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignup(signUpPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSignup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate Account
         * @param {ActivateAccountPayload} activateAccountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authActivateAccount(activateAccountPayload: ActivateAccountPayload, options?: RawAxiosRequestConfig): AxiosPromise<ActivateAccountResponse> {
            return localVarFp.authActivateAccount(activateAccountPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Forgot Password
         * @param {ForgotPasswordPayload} forgotPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPassword(forgotPasswordPayload: ForgotPasswordPayload, options?: RawAxiosRequestConfig): AxiosPromise<ForgotPasswordResponse> {
            return localVarFp.authForgotPassword(forgotPasswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh(refreshToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignInResponse> {
            return localVarFp.authRefresh(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend Activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResendActivation(options?: RawAxiosRequestConfig): AxiosPromise<ResendActivationResponse> {
            return localVarFp.authResendActivation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password
         * @param {ResetPasswordPayload} resetPasswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(resetPasswordPayload: ResetPasswordPayload, options?: RawAxiosRequestConfig): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.authResetPassword(resetPasswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth2 compatible login, get an access token for future requests
         * @summary Signin
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignin(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignInResponse> {
            return localVarFp.authSignin(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signout
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignout(refreshToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignOutResponse> {
            return localVarFp.authSignout(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signup
         * @param {SignUpPayload} signUpPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup(signUpPayload: SignUpPayload, options?: RawAxiosRequestConfig): AxiosPromise<SignUpResponse> {
            return localVarFp.authSignup(signUpPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Activate Account
     * @param {ActivateAccountPayload} activateAccountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authActivateAccount(activateAccountPayload: ActivateAccountPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authActivateAccount(activateAccountPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Forgot Password
     * @param {ForgotPasswordPayload} forgotPasswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authForgotPassword(forgotPasswordPayload: ForgotPasswordPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authForgotPassword(forgotPasswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefresh(refreshToken?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRefresh(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend Activation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResendActivation(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authResendActivation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password
     * @param {ResetPasswordPayload} resetPasswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResetPassword(resetPasswordPayload: ResetPasswordPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authResetPassword(resetPasswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth2 compatible login, get an access token for future requests
     * @summary Signin
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignin(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSignin(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signout
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignout(refreshToken?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSignout(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signup
     * @param {SignUpPayload} signUpPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignup(signUpPayload: SignUpPayload, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSignup(signUpPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CertificatesApi - axios parameter creator
 * @export
 */
export const CertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Certificate
         * @param {string} courseId 
         * @param {GenerateCertificatePayload} generateCertificatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesGenerateCertificate: async (courseId: string, generateCertificatePayload: GenerateCertificatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('certificatesGenerateCertificate', 'courseId', courseId)
            // verify required parameter 'generateCertificatePayload' is not null or undefined
            assertParamExists('certificatesGenerateCertificate', 'generateCertificatePayload', generateCertificatePayload)
            const localVarPath = `/api/v1/certificates/generate/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateCertificatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Certificates By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesReadCertificatesById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('certificatesReadCertificatesById', 'id', id)
            const localVarPath = `/api/v1/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Certificates Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesReadCertificatesMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/certificates/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesResendCertificate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('certificatesResendCertificate', 'id', id)
            const localVarPath = `/api/v1/certificates/resend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificatesApi - functional programming interface
 * @export
 */
export const CertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Certificate
         * @param {string} courseId 
         * @param {GenerateCertificatePayload} generateCertificatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certificatesGenerateCertificate(courseId: string, generateCertificatePayload: GenerateCertificatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certificatesGenerateCertificate(courseId, generateCertificatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificatesApi.certificatesGenerateCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Certificates By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certificatesReadCertificatesById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCertificateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certificatesReadCertificatesById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificatesApi.certificatesReadCertificatesById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Certificates Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certificatesReadCertificatesMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicCertificateSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certificatesReadCertificatesMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificatesApi.certificatesReadCertificatesMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certificatesResendCertificate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReSendCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certificatesResendCertificate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificatesApi.certificatesResendCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CertificatesApi - factory interface
 * @export
 */
export const CertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Certificate
         * @param {string} courseId 
         * @param {GenerateCertificatePayload} generateCertificatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesGenerateCertificate(courseId: string, generateCertificatePayload: GenerateCertificatePayload, options?: RawAxiosRequestConfig): AxiosPromise<GenerateCertificateResponse> {
            return localVarFp.certificatesGenerateCertificate(courseId, generateCertificatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Certificates By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesReadCertificatesById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<PublicCertificateSchema> {
            return localVarFp.certificatesReadCertificatesById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Certificates Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesReadCertificatesMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicCertificateSchema>> {
            return localVarFp.certificatesReadCertificatesMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend Certificate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificatesResendCertificate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ReSendCertificateResponse> {
            return localVarFp.certificatesResendCertificate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificatesApi - object-oriented interface
 * @export
 * @class CertificatesApi
 * @extends {BaseAPI}
 */
export class CertificatesApi extends BaseAPI {
    /**
     * 
     * @summary Generate Certificate
     * @param {string} courseId 
     * @param {GenerateCertificatePayload} generateCertificatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public certificatesGenerateCertificate(courseId: string, generateCertificatePayload: GenerateCertificatePayload, options?: RawAxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).certificatesGenerateCertificate(courseId, generateCertificatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Certificates By Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public certificatesReadCertificatesById(id: string, options?: RawAxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).certificatesReadCertificatesById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Certificates Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public certificatesReadCertificatesMe(options?: RawAxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).certificatesReadCertificatesMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend Certificate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public certificatesResendCertificate(id: string, options?: RawAxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).certificatesResendCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Buy Course
         * @param {PurchaseCoursePayload} purchaseCoursePayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesBuyCourse: async (purchaseCoursePayload: PurchaseCoursePayload, referrer?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseCoursePayload' is not null or undefined
            assertParamExists('coursesBuyCourse', 'purchaseCoursePayload', purchaseCoursePayload)
            const localVarPath = `/api/v1/courses/purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseCoursePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Lesson
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCompleteLesson: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('coursesCompleteLesson', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('coursesCompleteLesson', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('coursesCompleteLesson', 'lesson', lesson)
            const localVarPath = `/api/v1/courses/complete/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Video Presigned Url
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetVideoPresignedUrl: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('coursesGetVideoPresignedUrl', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('coursesGetVideoPresignedUrl', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('coursesGetVideoPresignedUrl', 'lesson', lesson)
            const localVarPath = `/api/v1/courses/get_video_url/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourse: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('coursesReadCourse', 'slug', slug)
            const localVarPath = `/api/v1/courses/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Course Data Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourseDataMe: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('coursesReadCourseDataMe', 'slug', slug)
            const localVarPath = `/api/v1/courses/{slug}/me`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/courses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Courses Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCoursesMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/courses/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Last Seen
         * @param {string} slug 
         * @param {UpdateLastSeenPayload} updateLastSeenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateLastSeen: async (slug: string, updateLastSeenPayload: UpdateLastSeenPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('coursesUpdateLastSeen', 'slug', slug)
            // verify required parameter 'updateLastSeenPayload' is not null or undefined
            assertParamExists('coursesUpdateLastSeen', 'updateLastSeenPayload', updateLastSeenPayload)
            const localVarPath = `/api/v1/courses/update_last_seen/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLastSeenPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Buy Course
         * @param {PurchaseCoursePayload} purchaseCoursePayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesBuyCourse(purchaseCoursePayload: PurchaseCoursePayload, referrer?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseCourseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesBuyCourse(purchaseCoursePayload, referrer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesBuyCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Complete Lesson
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesCompleteLesson(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteLessonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesCompleteLesson(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesCompleteLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Video Presigned Url
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetVideoPresignedUrl(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoPresignedURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetVideoPresignedUrl(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesGetVideoPresignedUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesReadCourse(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCourseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesReadCourse(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesReadCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Course Data Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesReadCourseDataMe(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCourseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesReadCourseDataMe(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesReadCourseDataMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesReadCourses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicCourseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesReadCourses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesReadCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Courses Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesReadCoursesMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCourseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesReadCoursesMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesReadCoursesMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Last Seen
         * @param {string} slug 
         * @param {UpdateLastSeenPayload} updateLastSeenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesUpdateLastSeen(slug: string, updateLastSeenPayload: UpdateLastSeenPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteLessonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesUpdateLastSeen(slug, updateLastSeenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.coursesUpdateLastSeen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         * 
         * @summary Buy Course
         * @param {PurchaseCoursePayload} purchaseCoursePayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesBuyCourse(purchaseCoursePayload: PurchaseCoursePayload, referrer?: string, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseCourseResponse> {
            return localVarFp.coursesBuyCourse(purchaseCoursePayload, referrer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete Lesson
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCompleteLesson(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<CompleteLessonResponse> {
            return localVarFp.coursesCompleteLesson(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Video Presigned Url
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetVideoPresignedUrl(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<VideoPresignedURLResponse> {
            return localVarFp.coursesGetVideoPresignedUrl(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Course
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourse(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<PublicCourseSchema> {
            return localVarFp.coursesReadCourse(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Course Data Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourseDataMe(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<UserCourseSchema> {
            return localVarFp.coursesReadCourseDataMe(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCourses(options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicCourseSchema>> {
            return localVarFp.coursesReadCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Courses Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesReadCoursesMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserCourseSchema>> {
            return localVarFp.coursesReadCoursesMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Last Seen
         * @param {string} slug 
         * @param {UpdateLastSeenPayload} updateLastSeenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateLastSeen(slug: string, updateLastSeenPayload: UpdateLastSeenPayload, options?: RawAxiosRequestConfig): AxiosPromise<CompleteLessonResponse> {
            return localVarFp.coursesUpdateLastSeen(slug, updateLastSeenPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @summary Buy Course
     * @param {PurchaseCoursePayload} purchaseCoursePayload 
     * @param {string} [referrer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesBuyCourse(purchaseCoursePayload: PurchaseCoursePayload, referrer?: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesBuyCourse(purchaseCoursePayload, referrer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete Lesson
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCompleteLesson(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesCompleteLesson(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Video Presigned Url
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetVideoPresignedUrl(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetVideoPresignedUrl(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Course
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesReadCourse(slug: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesReadCourse(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Course Data Me
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesReadCourseDataMe(slug: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesReadCourseDataMe(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesReadCourses(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesReadCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Courses Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesReadCoursesMe(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesReadCoursesMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Last Seen
     * @param {string} slug 
     * @param {UpdateLastSeenPayload} updateLastSeenPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesUpdateLastSeen(slug: string, updateLastSeenPayload: UpdateLastSeenPayload, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesUpdateLastSeen(slug, updateLastSeenPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExamsApi - axios parameter creator
 * @export
 */
export const ExamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Buy Exam
         * @param {PurchaseExamPayload} purchaseExamPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsBuyExam: async (purchaseExamPayload: PurchaseExamPayload, referrer?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseExamPayload' is not null or undefined
            assertParamExists('examsBuyExam', 'purchaseExamPayload', purchaseExamPayload)
            const localVarPath = `/api/v1/exams/purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseExamPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Has Examflag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsCheckHasExamflagMe: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('examsCheckHasExamflagMe', 'id', id)
            const localVarPath = `/api/v1/exams/flags/{id}/me`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extra Points Pr
         * @param {string} exam 
         * @param {ExtraPointsPRPayload} extraPointsPRPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsExtraPointsPr: async (exam: string, extraPointsPRPayload: ExtraPointsPRPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exam' is not null or undefined
            assertParamExists('examsExtraPointsPr', 'exam', exam)
            // verify required parameter 'extraPointsPRPayload' is not null or undefined
            assertParamExists('examsExtraPointsPr', 'extraPointsPRPayload', extraPointsPRPayload)
            const localVarPath = `/api/v1/exams/{exam}/extra_points_pr`
                .replace(`{${"exam"}}`, encodeURIComponent(String(exam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extraPointsPRPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExam: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('examsReadExam', 'slug', slug)
            const localVarPath = `/api/v1/exams/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Exam Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamMe: async (exam: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exam' is not null or undefined
            assertParamExists('examsReadExamMe', 'exam', exam)
            const localVarPath = `/api/v1/exams/{exam}/me`
                .replace(`{${"exam"}}`, encodeURIComponent(String(exam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Examflags Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamflagsMe: async (exam: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exam' is not null or undefined
            assertParamExists('examsReadExamflagsMe', 'exam', exam)
            const localVarPath = `/api/v1/exams/{exam}/flags/me`
                .replace(`{${"exam"}}`, encodeURIComponent(String(exam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExams: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Exams Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamsMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exams/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule Exam
         * @param {string} exam 
         * @param {RescheduleExamPayload} rescheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsRescheduleExam: async (exam: string, rescheduleExamPayload: RescheduleExamPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exam' is not null or undefined
            assertParamExists('examsRescheduleExam', 'exam', exam)
            // verify required parameter 'rescheduleExamPayload' is not null or undefined
            assertParamExists('examsRescheduleExam', 'rescheduleExamPayload', rescheduleExamPayload)
            const localVarPath = `/api/v1/exams/{exam}/reschedule`
                .replace(`{${"exam"}}`, encodeURIComponent(String(exam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rescheduleExamPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset exam
         * @summary Reset Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsResetExam: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('examsResetExam', 'slug', slug)
            const localVarPath = `/api/v1/exams/reset/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule Exam
         * @param {string} exam 
         * @param {ScheduleExamPayload} scheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsScheduleExam: async (exam: string, scheduleExamPayload: ScheduleExamPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exam' is not null or undefined
            assertParamExists('examsScheduleExam', 'exam', exam)
            // verify required parameter 'scheduleExamPayload' is not null or undefined
            assertParamExists('examsScheduleExam', 'scheduleExamPayload', scheduleExamPayload)
            const localVarPath = `/api/v1/exams/{exam}/schedule`
                .replace(`{${"exam"}}`, encodeURIComponent(String(exam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleExamPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Examflag Me
         * @param {string} id 
         * @param {SubmitExamFlagPayload} submitExamFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsSubmitExamflagMe: async (id: string, submitExamFlagPayload: SubmitExamFlagPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('examsSubmitExamflagMe', 'id', id)
            // verify required parameter 'submitExamFlagPayload' is not null or undefined
            assertParamExists('examsSubmitExamflagMe', 'submitExamFlagPayload', submitExamFlagPayload)
            const localVarPath = `/api/v1/exams/flags/{id}/me`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitExamFlagPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamsApi - functional programming interface
 * @export
 */
export const ExamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Buy Exam
         * @param {PurchaseExamPayload} purchaseExamPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsBuyExam(purchaseExamPayload: PurchaseExamPayload, referrer?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsBuyExam(purchaseExamPayload, referrer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsBuyExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check Has Examflag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsCheckHasExamflagMe(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckHasExamFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsCheckHasExamflagMe(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsCheckHasExamflagMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Extra Points Pr
         * @param {string} exam 
         * @param {ExtraPointsPRPayload} extraPointsPRPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsExtraPointsPr(exam: string, extraPointsPRPayload: ExtraPointsPRPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraPointsPRResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsExtraPointsPr(exam, extraPointsPRPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsExtraPointsPr']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsReadExam(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicExamSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsReadExam(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsReadExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Exam Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsReadExamMe(exam: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserExamSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsReadExamMe(exam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsReadExamMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Examflags Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsReadExamflagsMe(exam: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicExamFlagSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsReadExamflagsMe(exam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsReadExamflagsMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsReadExams(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicExamSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsReadExams(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsReadExams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Exams Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsReadExamsMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserExamSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsReadExamsMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsReadExamsMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reschedule Exam
         * @param {string} exam 
         * @param {RescheduleExamPayload} rescheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsRescheduleExam(exam: string, rescheduleExamPayload: RescheduleExamPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RescheduleExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsRescheduleExam(exam, rescheduleExamPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsRescheduleExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset exam
         * @summary Reset Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsResetExam(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsResetExam(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsResetExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Schedule Exam
         * @param {string} exam 
         * @param {ScheduleExamPayload} scheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsScheduleExam(exam: string, scheduleExamPayload: ScheduleExamPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleExamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsScheduleExam(exam, scheduleExamPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsScheduleExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Submit Examflag Me
         * @param {string} id 
         * @param {SubmitExamFlagPayload} submitExamFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examsSubmitExamflagMe(id: string, submitExamFlagPayload: SubmitExamFlagPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitExamFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examsSubmitExamflagMe(id, submitExamFlagPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExamsApi.examsSubmitExamflagMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExamsApi - factory interface
 * @export
 */
export const ExamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamsApiFp(configuration)
    return {
        /**
         * 
         * @summary Buy Exam
         * @param {PurchaseExamPayload} purchaseExamPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsBuyExam(purchaseExamPayload: PurchaseExamPayload, referrer?: string, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseExamResponse> {
            return localVarFp.examsBuyExam(purchaseExamPayload, referrer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Has Examflag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsCheckHasExamflagMe(id: string, options?: RawAxiosRequestConfig): AxiosPromise<CheckHasExamFlagResponse> {
            return localVarFp.examsCheckHasExamflagMe(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extra Points Pr
         * @param {string} exam 
         * @param {ExtraPointsPRPayload} extraPointsPRPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsExtraPointsPr(exam: string, extraPointsPRPayload: ExtraPointsPRPayload, options?: RawAxiosRequestConfig): AxiosPromise<ExtraPointsPRResponse> {
            return localVarFp.examsExtraPointsPr(exam, extraPointsPRPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExam(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<PublicExamSchema> {
            return localVarFp.examsReadExam(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Exam Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamMe(exam: string, options?: RawAxiosRequestConfig): AxiosPromise<UserExamSchema> {
            return localVarFp.examsReadExamMe(exam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Examflags Me
         * @param {string} exam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamflagsMe(exam: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicExamFlagSchema>> {
            return localVarFp.examsReadExamflagsMe(exam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Exams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExams(options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicExamSchema>> {
            return localVarFp.examsReadExams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Exams Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsReadExamsMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserExamSchema>> {
            return localVarFp.examsReadExamsMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedule Exam
         * @param {string} exam 
         * @param {RescheduleExamPayload} rescheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsRescheduleExam(exam: string, rescheduleExamPayload: RescheduleExamPayload, options?: RawAxiosRequestConfig): AxiosPromise<RescheduleExamResponse> {
            return localVarFp.examsRescheduleExam(exam, rescheduleExamPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset exam
         * @summary Reset Exam
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsResetExam(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<ResetExamResponse> {
            return localVarFp.examsResetExam(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule Exam
         * @param {string} exam 
         * @param {ScheduleExamPayload} scheduleExamPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsScheduleExam(exam: string, scheduleExamPayload: ScheduleExamPayload, options?: RawAxiosRequestConfig): AxiosPromise<ScheduleExamResponse> {
            return localVarFp.examsScheduleExam(exam, scheduleExamPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit Examflag Me
         * @param {string} id 
         * @param {SubmitExamFlagPayload} submitExamFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examsSubmitExamflagMe(id: string, submitExamFlagPayload: SubmitExamFlagPayload, options?: RawAxiosRequestConfig): AxiosPromise<SubmitExamFlagResponse> {
            return localVarFp.examsSubmitExamflagMe(id, submitExamFlagPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExamsApi - object-oriented interface
 * @export
 * @class ExamsApi
 * @extends {BaseAPI}
 */
export class ExamsApi extends BaseAPI {
    /**
     * 
     * @summary Buy Exam
     * @param {PurchaseExamPayload} purchaseExamPayload 
     * @param {string} [referrer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsBuyExam(purchaseExamPayload: PurchaseExamPayload, referrer?: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsBuyExam(purchaseExamPayload, referrer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Has Examflag Me
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsCheckHasExamflagMe(id: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsCheckHasExamflagMe(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extra Points Pr
     * @param {string} exam 
     * @param {ExtraPointsPRPayload} extraPointsPRPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsExtraPointsPr(exam: string, extraPointsPRPayload: ExtraPointsPRPayload, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsExtraPointsPr(exam, extraPointsPRPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Exam
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsReadExam(slug: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsReadExam(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Exam Me
     * @param {string} exam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsReadExamMe(exam: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsReadExamMe(exam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Examflags Me
     * @param {string} exam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsReadExamflagsMe(exam: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsReadExamflagsMe(exam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Exams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsReadExams(options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsReadExams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Exams Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsReadExamsMe(options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsReadExamsMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedule Exam
     * @param {string} exam 
     * @param {RescheduleExamPayload} rescheduleExamPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsRescheduleExam(exam: string, rescheduleExamPayload: RescheduleExamPayload, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsRescheduleExam(exam, rescheduleExamPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset exam
     * @summary Reset Exam
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsResetExam(slug: string, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsResetExam(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule Exam
     * @param {string} exam 
     * @param {ScheduleExamPayload} scheduleExamPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsScheduleExam(exam: string, scheduleExamPayload: ScheduleExamPayload, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsScheduleExam(exam, scheduleExamPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit Examflag Me
     * @param {string} id 
     * @param {SubmitExamFlagPayload} submitExamFlagPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamsApi
     */
    public examsSubmitExamflagMe(id: string, submitExamFlagPayload: SubmitExamFlagPayload, options?: RawAxiosRequestConfig) {
        return ExamsApiFp(this.configuration).examsSubmitExamflagMe(id, submitExamFlagPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FlagsApi - axios parameter creator
 * @export
 */
export const FlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check Has Flag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsCheckHasFlagMe: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flagsCheckHasFlagMe', 'id', id)
            const localVarPath = `/api/v1/flags/check/{id}/me`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Flags Lab Me
         * @param {string} lab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsReadFlagsLabMe: async (lab: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lab' is not null or undefined
            assertParamExists('flagsReadFlagsLabMe', 'lab', lab)
            const localVarPath = `/api/v1/flags/{lab}/me`
                .replace(`{${"lab"}}`, encodeURIComponent(String(lab)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Flags Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsReadFlagsMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/flags/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Flag Me
         * @param {string} id 
         * @param {SubmitFlagPayload} submitFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsSubmitFlagMe: async (id: string, submitFlagPayload: SubmitFlagPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flagsSubmitFlagMe', 'id', id)
            // verify required parameter 'submitFlagPayload' is not null or undefined
            assertParamExists('flagsSubmitFlagMe', 'submitFlagPayload', submitFlagPayload)
            const localVarPath = `/api/v1/flags/{id}/me`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitFlagPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlagsApi - functional programming interface
 * @export
 */
export const FlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check Has Flag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagsCheckHasFlagMe(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckHasFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagsCheckHasFlagMe(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlagsApi.flagsCheckHasFlagMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Flags Lab Me
         * @param {string} lab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagsReadFlagsLabMe(lab: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicFlagSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagsReadFlagsLabMe(lab, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlagsApi.flagsReadFlagsLabMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Flags Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagsReadFlagsMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicFlagSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagsReadFlagsMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlagsApi.flagsReadFlagsMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Submit Flag Me
         * @param {string} id 
         * @param {SubmitFlagPayload} submitFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagsSubmitFlagMe(id: string, submitFlagPayload: SubmitFlagPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagsSubmitFlagMe(id, submitFlagPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlagsApi.flagsSubmitFlagMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FlagsApi - factory interface
 * @export
 */
export const FlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check Has Flag Me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsCheckHasFlagMe(id: string, options?: RawAxiosRequestConfig): AxiosPromise<CheckHasFlagResponse> {
            return localVarFp.flagsCheckHasFlagMe(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Flags Lab Me
         * @param {string} lab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsReadFlagsLabMe(lab: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicFlagSchema>> {
            return localVarFp.flagsReadFlagsLabMe(lab, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Flags Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsReadFlagsMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicFlagSchema>> {
            return localVarFp.flagsReadFlagsMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit Flag Me
         * @param {string} id 
         * @param {SubmitFlagPayload} submitFlagPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagsSubmitFlagMe(id: string, submitFlagPayload: SubmitFlagPayload, options?: RawAxiosRequestConfig): AxiosPromise<SubmitFlagResponse> {
            return localVarFp.flagsSubmitFlagMe(id, submitFlagPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlagsApi - object-oriented interface
 * @export
 * @class FlagsApi
 * @extends {BaseAPI}
 */
export class FlagsApi extends BaseAPI {
    /**
     * 
     * @summary Check Has Flag Me
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagsApi
     */
    public flagsCheckHasFlagMe(id: string, options?: RawAxiosRequestConfig) {
        return FlagsApiFp(this.configuration).flagsCheckHasFlagMe(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Flags Lab Me
     * @param {string} lab 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagsApi
     */
    public flagsReadFlagsLabMe(lab: string, options?: RawAxiosRequestConfig) {
        return FlagsApiFp(this.configuration).flagsReadFlagsLabMe(lab, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Flags Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagsApi
     */
    public flagsReadFlagsMe(options?: RawAxiosRequestConfig) {
        return FlagsApiFp(this.configuration).flagsReadFlagsMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit Flag Me
     * @param {string} id 
     * @param {SubmitFlagPayload} submitFlagPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagsApi
     */
    public flagsSubmitFlagMe(id: string, submitFlagPayload: SubmitFlagPayload, options?: RawAxiosRequestConfig) {
        return FlagsApiFp(this.configuration).flagsSubmitFlagMe(id, submitFlagPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthHealth(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.healthHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthHealth(options?: RawAxiosRequestConfig): AxiosPromise<HealthResponse> {
            return localVarFp.healthHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthHealth(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).healthHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LabsApi - axios parameter creator
 * @export
 */
export const LabsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add Extra lab data like email for GCP whitebox SA
         * @summary Add Extra Data Lab
         * @param {string} slug 
         * @param {AddExtraDataLabPayload} addExtraDataLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsAddExtraDataLab: async (slug: string, addExtraDataLabPayload: AddExtraDataLabPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsAddExtraDataLab', 'slug', slug)
            // verify required parameter 'addExtraDataLabPayload' is not null or undefined
            assertParamExists('labsAddExtraDataLab', 'addExtraDataLabPayload', addExtraDataLabPayload)
            const localVarPath = `/api/v1/labs/add_extra_data/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addExtraDataLabPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Buy Lab
         * @param {PurchaseLabPayload} purchaseLabPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsBuyLab: async (purchaseLabPayload: PurchaseLabPayload, referrer?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseLabPayload' is not null or undefined
            assertParamExists('labsBuyLab', 'purchaseLabPayload', purchaseLabPayload)
            const localVarPath = `/api/v1/labs/purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseLabPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deploy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsDeployLessonLab: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsDeployLessonLab', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('labsDeployLessonLab', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('labsDeployLessonLab', 'lesson', lesson)
            const localVarPath = `/api/v1/labs/deploy/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Destroy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsDestroyLessonLab: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsDestroyLessonLab', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('labsDestroyLessonLab', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('labsDestroyLessonLab', 'lesson', lesson)
            const localVarPath = `/api/v1/labs/destroy/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extend Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsExtendLessonLab: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsExtendLessonLab', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('labsExtendLessonLab', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('labsExtendLessonLab', 'lesson', lesson)
            const localVarPath = `/api/v1/labs/extend/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLab: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsReadLab', 'slug', slug)
            const localVarPath = `/api/v1/labs/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Lab Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabMe: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsReadLabMe', 'slug', slug)
            const localVarPath = `/api/v1/labs/{slug}/me`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Labs Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabsMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labs/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsResetLab: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsResetLab', 'slug', slug)
            const localVarPath = `/api/v1/labs/reset/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsResetLessonLab: async (slug: string, section: string, lesson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('labsResetLessonLab', 'slug', slug)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('labsResetLessonLab', 'section', section)
            // verify required parameter 'lesson' is not null or undefined
            assertParamExists('labsResetLessonLab', 'lesson', lesson)
            const localVarPath = `/api/v1/labs/reset/{slug}/{section}/{lesson}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"lesson"}}`, encodeURIComponent(String(lesson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabsApi - functional programming interface
 * @export
 */
export const LabsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add Extra lab data like email for GCP whitebox SA
         * @summary Add Extra Data Lab
         * @param {string} slug 
         * @param {AddExtraDataLabPayload} addExtraDataLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsAddExtraDataLab(slug: string, addExtraDataLabPayload: AddExtraDataLabPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddExtraDataLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsAddExtraDataLab(slug, addExtraDataLabPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsAddExtraDataLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Buy Lab
         * @param {PurchaseLabPayload} purchaseLabPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsBuyLab(purchaseLabPayload: PurchaseLabPayload, referrer?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsBuyLab(purchaseLabPayload, referrer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsBuyLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deploy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsDeployLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeployLessonLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsDeployLessonLab(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsDeployLessonLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Destroy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsDestroyLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroyLessonLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsDestroyLessonLab(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsDestroyLessonLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Extend Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsExtendLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendLessonLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsExtendLessonLab(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsExtendLessonLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsReadLab(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicLabSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsReadLab(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsReadLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Lab Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsReadLabMe(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLabSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsReadLabMe(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsReadLabMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsReadLabs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicLabSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsReadLabs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsReadLabs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Labs Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsReadLabsMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserLabSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsReadLabsMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsReadLabsMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsResetLab(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsResetLab(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsResetLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labsResetLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetLessonLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labsResetLessonLab(slug, section, lesson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabsApi.labsResetLessonLab']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LabsApi - factory interface
 * @export
 */
export const LabsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabsApiFp(configuration)
    return {
        /**
         * Add Extra lab data like email for GCP whitebox SA
         * @summary Add Extra Data Lab
         * @param {string} slug 
         * @param {AddExtraDataLabPayload} addExtraDataLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsAddExtraDataLab(slug: string, addExtraDataLabPayload: AddExtraDataLabPayload, options?: RawAxiosRequestConfig): AxiosPromise<AddExtraDataLabResponse> {
            return localVarFp.labsAddExtraDataLab(slug, addExtraDataLabPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Buy Lab
         * @param {PurchaseLabPayload} purchaseLabPayload 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsBuyLab(purchaseLabPayload: PurchaseLabPayload, referrer?: string, options?: RawAxiosRequestConfig): AxiosPromise<PurchaseLabResponse> {
            return localVarFp.labsBuyLab(purchaseLabPayload, referrer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deploy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsDeployLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<DeployLessonLabResponse> {
            return localVarFp.labsDeployLessonLab(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Destroy Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsDestroyLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<DestroyLessonLabResponse> {
            return localVarFp.labsDestroyLessonLab(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extend Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsExtendLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<ExtendLessonLabResponse> {
            return localVarFp.labsExtendLessonLab(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLab(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<PublicLabSchema> {
            return localVarFp.labsReadLab(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Lab Me
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabMe(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<UserLabSchema> {
            return localVarFp.labsReadLabMe(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Labs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabs(options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicLabSchema>> {
            return localVarFp.labsReadLabs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Labs Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsReadLabsMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserLabSchema>> {
            return localVarFp.labsReadLabsMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Reset lab
         * @summary Reset Lab
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsResetLab(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<ResetLabResponse> {
            return localVarFp.labsResetLab(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Lesson Lab
         * @param {string} slug 
         * @param {string} section 
         * @param {string} lesson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labsResetLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig): AxiosPromise<ResetLessonLabResponse> {
            return localVarFp.labsResetLessonLab(slug, section, lesson, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabsApi - object-oriented interface
 * @export
 * @class LabsApi
 * @extends {BaseAPI}
 */
export class LabsApi extends BaseAPI {
    /**
     * Add Extra lab data like email for GCP whitebox SA
     * @summary Add Extra Data Lab
     * @param {string} slug 
     * @param {AddExtraDataLabPayload} addExtraDataLabPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsAddExtraDataLab(slug: string, addExtraDataLabPayload: AddExtraDataLabPayload, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsAddExtraDataLab(slug, addExtraDataLabPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Buy Lab
     * @param {PurchaseLabPayload} purchaseLabPayload 
     * @param {string} [referrer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsBuyLab(purchaseLabPayload: PurchaseLabPayload, referrer?: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsBuyLab(purchaseLabPayload, referrer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deploy Lesson Lab
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsDeployLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsDeployLessonLab(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Destroy Lesson Lab
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsDestroyLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsDestroyLessonLab(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extend Lesson Lab
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsExtendLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsExtendLessonLab(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Lab
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsReadLab(slug: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsReadLab(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Lab Me
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsReadLabMe(slug: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsReadLabMe(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Labs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsReadLabs(options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsReadLabs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Labs Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsReadLabsMe(options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsReadLabsMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset lab
     * @summary Reset Lab
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsResetLab(slug: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsResetLab(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Lesson Lab
     * @param {string} slug 
     * @param {string} section 
     * @param {string} lesson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public labsResetLessonLab(slug: string, section: string, lesson: string, options?: RawAxiosRequestConfig) {
        return LabsApiFp(this.configuration).labsResetLessonLab(slug, section, lesson, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Handle Checkout Complete
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeHandleCheckoutComplete: async (stripeSignature?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stripe/checkout_complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Handle Checkout Complete
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeHandleCheckoutComplete(stripeSignature?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookCheckoutCompleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeHandleCheckoutComplete(stripeSignature, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeHandleCheckoutComplete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @summary Handle Checkout Complete
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeHandleCheckoutComplete(stripeSignature?: string, options?: RawAxiosRequestConfig): AxiosPromise<WebhookCheckoutCompleteResponse> {
            return localVarFp.stripeHandleCheckoutComplete(stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @summary Handle Checkout Complete
     * @param {string} [stripeSignature] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeHandleCheckoutComplete(stripeSignature?: string, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeHandleCheckoutComplete(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUserMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user
         * @summary Update User Me
         * @param {ProfileUpdatePayload} profileUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserMe: async (profileUpdatePayload: ProfileUpdatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdatePayload' is not null or undefined
            assertParamExists('usersUpdateUserMe', 'profileUpdatePayload', profileUpdatePayload)
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersReadUserMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateUserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersReadUserMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersReadUserMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update current user
         * @summary Update User Me
         * @param {ProfileUpdatePayload} profileUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserMe(profileUpdatePayload: ProfileUpdatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserMe(profileUpdatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUpdateUserMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Get current user
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUserMe(options?: RawAxiosRequestConfig): AxiosPromise<PrivateUserSchema> {
            return localVarFp.usersReadUserMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user
         * @summary Update User Me
         * @param {ProfileUpdatePayload} profileUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserMe(profileUpdatePayload: ProfileUpdatePayload, options?: RawAxiosRequestConfig): AxiosPromise<ProfileUpdateResponse> {
            return localVarFp.usersUpdateUserMe(profileUpdatePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Get current user
     * @summary Read User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersReadUserMe(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersReadUserMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user
     * @summary Update User Me
     * @param {ProfileUpdatePayload} profileUpdatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserMe(profileUpdatePayload: ProfileUpdatePayload, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUserMe(profileUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VouchersApi - axios parameter creator
 * @export
 */
export const VouchersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate Voucher
         * @param {ActivateVoucherPayload} activateVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersActivateVoucher: async (activateVoucherPayload: ActivateVoucherPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateVoucherPayload' is not null or undefined
            assertParamExists('vouchersActivateVoucher', 'activateVoucherPayload', activateVoucherPayload)
            const localVarPath = `/api/v1/vouchers/activate_voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateVoucherPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Vouchers Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersReadVouchersMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vouchers/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend Voucher
         * @param {ReSendVoucherPayload} reSendVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersResendVoucher: async (reSendVoucherPayload: ReSendVoucherPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reSendVoucherPayload' is not null or undefined
            assertParamExists('vouchersResendVoucher', 'reSendVoucherPayload', reSendVoucherPayload)
            const localVarPath = `/api/v1/vouchers/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reSendVoucherPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VouchersApi - functional programming interface
 * @export
 */
export const VouchersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VouchersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate Voucher
         * @param {ActivateVoucherPayload} activateVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vouchersActivateVoucher(activateVoucherPayload: ActivateVoucherPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateVoucherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vouchersActivateVoucher(activateVoucherPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.vouchersActivateVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Vouchers Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vouchersReadVouchersMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivateVoucherSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vouchersReadVouchersMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.vouchersReadVouchersMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend Voucher
         * @param {ReSendVoucherPayload} reSendVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vouchersResendVoucher(reSendVoucherPayload: ReSendVoucherPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReSendVoucherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vouchersResendVoucher(reSendVoucherPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.vouchersResendVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VouchersApi - factory interface
 * @export
 */
export const VouchersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VouchersApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate Voucher
         * @param {ActivateVoucherPayload} activateVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersActivateVoucher(activateVoucherPayload: ActivateVoucherPayload, options?: RawAxiosRequestConfig): AxiosPromise<ActivateVoucherResponse> {
            return localVarFp.vouchersActivateVoucher(activateVoucherPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Vouchers Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersReadVouchersMe(options?: RawAxiosRequestConfig): AxiosPromise<Array<PrivateVoucherSchema>> {
            return localVarFp.vouchersReadVouchersMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend Voucher
         * @param {ReSendVoucherPayload} reSendVoucherPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersResendVoucher(reSendVoucherPayload: ReSendVoucherPayload, options?: RawAxiosRequestConfig): AxiosPromise<ReSendVoucherResponse> {
            return localVarFp.vouchersResendVoucher(reSendVoucherPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VouchersApi - object-oriented interface
 * @export
 * @class VouchersApi
 * @extends {BaseAPI}
 */
export class VouchersApi extends BaseAPI {
    /**
     * 
     * @summary Activate Voucher
     * @param {ActivateVoucherPayload} activateVoucherPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public vouchersActivateVoucher(activateVoucherPayload: ActivateVoucherPayload, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).vouchersActivateVoucher(activateVoucherPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Vouchers Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public vouchersReadVouchersMe(options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).vouchersReadVouchersMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend Voucher
     * @param {ReSendVoucherPayload} reSendVoucherPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public vouchersResendVoucher(reSendVoucherPayload: ReSendVoucherPayload, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).vouchersResendVoucher(reSendVoucherPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



