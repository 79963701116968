import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { isAxiosError } from "axios"
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Progress,
  Tooltip,
  Datepicker,
  Label,
  Select,
  TextInput,
  Radio,
} from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import {
  ExamTimeEnum,
  UserCourseSchema,
  UserExamSchema,
  UserLabSchema,
} from "../../api"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import useAuthedApi from "../../hooks/useAuthedApi"
import { Link, useNavigate } from "react-router-dom"
import Loading from "../../components/Loading"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import GRTA_CAN from "../../assets/images/GRTA_CAN.png"
import { BiReset } from "react-icons/bi"
import { HiInformationCircle, HiOutlineExclamationCircle } from "react-icons/hi"
import useToasts from "../../hooks/useToasts"
import Timer from "../../components/Timer"
import { LuPartyPopper } from "react-icons/lu"
import { VscRequestChanges } from "react-icons/vsc"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeAttrs from "rehype-attr"

const Dashboard = () => {
  const [userCourses, setUserCourses] = useState<UserCourseSchema[]>()
  const [openCourseChangelogModal, setOpenCourseChangelogModal] =
    useState<boolean>(false)
  const [courseToCourseChangelog, setCourseToCourseChangelog] =
    useState<UserCourseSchema>()
  const [userLabs, setUserLabs] = useState<UserLabSchema[]>()
  const [labsProgress, setLabsProgress] = useState<any>({})
  const [openLabResetModal, setOpenLabResetModal] = useState<boolean>(false)
  const [labResetErrMsg, setLabResetErrMsg] = useState("")
  const labResetErrRef = useRef<HTMLInputElement>(null)
  const [labToReset, setLabToReset] = useState<UserLabSchema>()
  const [openLabExtendModal, setOpenLabExtendModal] = useState<boolean>(false)
  const [labToExtend, setLabToExtend] = useState<UserLabSchema>()
  const [extendLabErrMsg, setExtendLabErrMsg] = useState("")
  const extendLabErrRef = useRef<HTMLInputElement>(null)
  const [labExtensionDuration, setLabExtensionDuration] = useState<number>(30)
  const [labExtensionQuantity, setLabExtensionQuantity] = useState<string>("1")
  const [validLabExtensionQuantity, setValidLabExtensionQuantity] =
    useState(false)
  const [labExtensionQuantityFocus, setLabExtensionQuantityFocus] =
    useState(false)

  const [openExamAttemptModal, setOpenExamAttemptModal] =
    useState<boolean>(false)
  const [examAttemptToBuy, setExamAttemptToBuy] = useState<UserExamSchema>()
  const [examAttemptErrMsg, setExamAttemptErrMsg] = useState("")
  const examAttemptErrRef = useRef<HTMLInputElement>(null)
  const [examAttemptQuantity, setExamAttemptQuantity] = useState<string>("1")
  const [validExamAttemptQuantity, setValidExamAttemptQuantity] =
    useState(false)
  const [examAttemptQuantityFocus, setExamAttemptQuantityFocus] =
    useState(false)

  const [userExams, setUserExams] = useState<UserExamSchema[]>()
  const [examsProgress, setExamsProgress] = useState<any>({})
  const [openScheduleExamModal, setOpenScheduleExamModal] =
    useState<boolean>(false)
  const [scheduleExamErrMsg, setScheduleExamErrMsg] = useState("")
  const scheduleExamErrRef = useRef<HTMLInputElement>(null)
  const [examToSchedule, setExamToSchedule] = useState<UserExamSchema>()
  const [examToScheduleDate, setExamToScheduleDate] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  )
  const [examToScheduleTime, setExamToScheduleTime] = useState<ExamTimeEnum>(
    ExamTimeEnum.NUMBER_0
  )
  const [isExamScheduling, setIsExamScheduling] = useState<boolean>(false)

  const [openRescheduleExamModal, setOpenRescheduleExamModal] =
    useState<boolean>(false)
  const [rescheduleExamErrMsg, setRescheduleExamErrMsg] = useState("")
  const rescheduleExamErrRef = useRef<HTMLInputElement>(null)
  const [examToReschedule, setExamToReschedule] = useState<UserExamSchema>()
  const [examToRescheduleDate, setExamToRescheduleDate] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  )
  const [examToRescheduleTime, setExamToRescheduleTime] =
    useState<ExamTimeEnum>(ExamTimeEnum.NUMBER_0)
  const [isExamRescheduling, setIsExamRescheduling] = useState<boolean>(false)

  const [openExtraPointsPRModal, setOpenExtraPointsPRModal] =
    useState<boolean>(false)
  const [examToExtraPointsPR, setExamToExtraPointsPR] =
    useState<UserExamSchema>()
  const [extraPointsPRErrMsg, setExtraPointsPRErrMsg] = useState("")
  const extraPointsPRErrRef = useRef<HTMLInputElement>(null)
  const [extraPointsPR, setExtraPointsPR] = useState("")
  const [validExtraPointsPR, setValidExtraPointsPR] = useState(false)
  const [extraPointsPRFocus, setExtraPointsPRFocus] = useState(false)
  const [loadingCourses, setLoadingCourses] = useState(true)
  const [loadingLabs, setLoadingLabs] = useState(true)
  const [loadingExams, setLoadingExams] = useState(true)
  const [now, setNow] = useState(new Date())
  const navigate = useNavigate()
  const { CoursesAuthedApi, LabsAuthedApi, FlagsAuthedApi, ExamsAuthedApi } =
    useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()

  const getUserCourses = async () => {
    try {
      const response = await CoursesAuthedApi.coursesReadCoursesMe()
      if (!response.data) {
        setErrMsg("Get courses failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserCourses(response.data)
      setLoadingCourses(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get courses failed with unknown error")
        }
      } else {
        setErrMsg("Get courses failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const getUserLabs = async () => {
    try {
      const response = await LabsAuthedApi.labsReadLabsMe()
      if (!response.data) {
        setErrMsg("Get labs failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserLabs(response.data)
      response.data.forEach(async (userLab) => {
        const response = await FlagsAuthedApi.flagsReadFlagsLabMe(
          userLab.lab.slug
        )
        if (!response.data) {
          setErrMsg("Get labs completed flags failed with unknown error")
        }

        const completedCount = response.data.length

        let tmp = labsProgress
        tmp[userLab.lab.slug] = completedCount
        setLabsProgress(tmp)
      })

      setLoadingLabs(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get labs failed with unknown error")
        }
      } else {
        setErrMsg("Get labs failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const resetLab = async (userLab: UserLabSchema | undefined) => {
    if (!userLab || !userLabs) {
      setLabResetErrMsg("Lab to reset is undefined")
      labResetErrRef.current?.focus()
      return
    }
    try {
      const response = await LabsAuthedApi.labsResetLab(userLab.lab.slug)
      if (!response.data) {
        setErrMsg("Lab reset failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      const index = userLabs?.findIndex((u) => {
        return u.lab.id === userLab?.lab.id
      })

      userLab = response.data.data
      userLabs[index] = userLab
      setOpenLabResetModal(false)
      setLabToReset(undefined)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Lab reset started successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setLabResetErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setLabResetErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setLabResetErrMsg(err.response.data.detail)
        } else {
          setLabResetErrMsg("Lab reset failed with unknown error")
        }
      } else {
        setLabResetErrMsg("Lab reset failed with unknown error")
      }
      labResetErrRef.current?.focus()
    }
  }

  const getUserExams = async () => {
    try {
      const response = await ExamsAuthedApi.examsReadExamsMe()
      if (!response.data) {
        setErrMsg("Get exams failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserExams(response.data)
      let tmp = { ...examsProgress }
      response.data.forEach(async (userExam) => {
        const response = await ExamsAuthedApi.examsReadExamflagsMe(
          userExam.exam.slug
        )
        if (!response.data) {
          setErrMsg("Get exams completed flags failed with unknown error")
        }
        const completedCount = response.data.length
        tmp[userExam.exam.slug] = completedCount
      })
      setExamsProgress(tmp)

      setLoadingExams(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get exams failed with unknown error")
        }
      } else {
        setErrMsg("Get exams failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const calculateProgressCourse = (userCourse: UserCourseSchema) => {
    if (
      userCourse.course.sections === null ||
      userCourse.course.sections === undefined ||
      userCourse.course.sections.length === 0
    ) {
      return 0
    }
    const lessonCount = userCourse.course.sections
      .map((s) => {
        return s.lessons.length
      })
      .reduce((prev, next) => prev + next)

    if (!userCourse.data || !(userCourse.data as any).sections) {
      return 0
    }

    const finishedLessonCount = Object.values((userCourse.data as any).sections)
      .map((s: any) => {
        return Object.values(s.lessons)
          .map((l: any) => {
            return l.completed ? 1 : (0 as number)
          })
          .reduce((prev, next) => prev + next)
      })
      .reduce((prev, next) => prev + next)

    return Math.round((finishedLessonCount / lessonCount) * 100)
  }

  const calculateTimeLeftLab = (userLab: UserLabSchema) => {
    let until = new Date(userLab.active_until)
    until.setHours(0, 0, 0, 0)
    let timeLeft = Math.floor(
      (until.getTime() - now.getTime()) / 1000 / 60 / 60 / 24
    )
    return timeLeft < 0 ? 0 : timeLeft
  }

  const calculateTimeLeftExam = (userExam: UserExamSchema) => {
    let delta: number

    if (!userExam.exam_start || !userExam.exam_end) {
      let until = new Date(userExam.active_until)
      delta = Math.floor((until.getTime() - now.getTime()) / 1000)
      return delta
    }

    let start = new Date(userExam.exam_start)
    let end = new Date(userExam.exam_end)

    if (start > now) {
      // exam not started
      delta = Math.floor((start.getTime() - now.getTime()) / 1000)
      return delta
    } else if (start < now && now < end) {
      // exam started
      delta = Math.floor((end.getTime() - now.getTime()) / 1000)
      return delta
    } else {
      // exam ended
      return 0
    }
  }

  function timeConversion(duration: number) {
    const portions: string[] = []

    const msInHour = 1000 * 60 * 60
    const hours = Math.trunc(duration / msInHour)
    if (hours > 0) {
      portions.push(hours + "h")
      duration = duration - hours * msInHour
    }

    const msInMinute = 1000 * 60
    const minutes = Math.trunc(duration / msInMinute)
    if (minutes > 0) {
      portions.push(minutes + "m")
      duration = duration - minutes * msInMinute
    }

    const seconds = Math.trunc(duration / 1000)
    if (seconds > 0) {
      portions.push(seconds + "s")
    }

    return portions.join(" ")
  }

  const scheduleExam = async (userExam: UserExamSchema | undefined) => {
    if (!userExam || !userExams) {
      setScheduleExamErrMsg("Schedule exams failed with unknown error")
      return
    }
    if (isExamScheduling) {
      return
    }
    setIsExamScheduling(true)
    setScheduleExamErrMsg("")
    let d = new Date(examToScheduleDate)
    d = new Date(d.setMinutes(d.getMinutes() - d.getTimezoneOffset()))
    try {
      const response = await ExamsAuthedApi.examsScheduleExam(
        userExam?.exam.slug,
        { date: d.toISOString(), time: examToScheduleTime }
      )
      if (!response.data) {
        setScheduleExamErrMsg("Error scheduling exam")
        setIsExamScheduling(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      const index = userExams?.findIndex((ue) => {
        return ue.exam.id === userExam?.exam.id
      })
      const nextUserExams = userExams.map((ue, i) => {
        if (i === index) {
          return response.data.user_exam
        } else {
          return ue
        }
      })
      setUserExams(nextUserExams)
      setOpenScheduleExamModal(false)
      setExamToSchedule(undefined)
      setExamToScheduleDate(new Date(new Date().setHours(0, 0, 0, 0)))
      setExamToScheduleTime(ExamTimeEnum.NUMBER_0)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Exam scheduled successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setScheduleExamErrMsg("No server response")
        } else if (err.response?.status === 422) {
          console.log(err.response)
          setScheduleExamErrMsg(err.response.data.detail[0].msg)
        } else if (err.response?.status === 400) {
          console.log(err.response)
          setScheduleExamErrMsg(err.response.data.detail)
        } else {
          setScheduleExamErrMsg("Schedule exam failed with unknown error")
        }
      } else {
        setScheduleExamErrMsg("Schedule exam failed with unknown error")
      }
      scheduleExamErrRef.current?.focus()
    }
    setIsExamScheduling(false)
  }

  const rescheduleExam = async (userExam: UserExamSchema | undefined) => {
    if (!userExam || !userExams) {
      setRescheduleExamErrMsg("Reschedule exams failed with unknown error")
      return
    }
    if (isExamRescheduling) {
      return
    }
    setIsExamRescheduling(true)
    setRescheduleExamErrMsg("")
    let d = new Date(examToRescheduleDate)
    d = new Date(d.setMinutes(d.getMinutes() - d.getTimezoneOffset()))
    try {
      const response = await ExamsAuthedApi.examsRescheduleExam(
        userExam?.exam.slug,
        { date: d.toISOString(), time: examToRescheduleTime }
      )
      if (!response.data) {
        setRescheduleExamErrMsg("Error rescheduling exam")
        setIsExamRescheduling(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      const index = userExams?.findIndex((ue) => {
        return ue.exam.id === userExam?.exam.id
      })
      const nextUserExams = userExams.map((ue, i) => {
        if (i === index) {
          return response.data.user_exam
        } else {
          return ue
        }
      })
      setUserExams(nextUserExams)
      setOpenRescheduleExamModal(false)
      setExamToReschedule(undefined)
      setExamToRescheduleDate(new Date(new Date().setHours(0, 0, 0, 0)))
      setExamToRescheduleTime(ExamTimeEnum.NUMBER_0)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Exam rescheduled successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setRescheduleExamErrMsg("No server response")
        } else if (err.response?.status === 422) {
          console.log(err.response)
          setRescheduleExamErrMsg(err.response.data.detail[0].msg)
        } else if (err.response?.status === 400) {
          console.log(err.response)
          setRescheduleExamErrMsg(err.response.data.detail)
        } else {
          setRescheduleExamErrMsg("Reschedule exam failed with unknown error")
        }
      } else {
        setRescheduleExamErrMsg("Reschedule exam failed with unknown error")
      }
      rescheduleExamErrRef.current?.focus()
      setIsExamRescheduling(false)
    }
  }

  const submitExtraPointsPR = async (userExam: UserExamSchema | undefined) => {
    if (!userExam || !userExams) {
      setExtraPointsPRErrMsg(
        "Submitting extra points PR failed with unknown error"
      )
      return
    }
    setExtraPointsPRErrMsg("")
    try {
      const response = await ExamsAuthedApi.examsExtraPointsPr(
        userExam?.exam.slug,
        { pr_number: extraPointsPR }
      )
      if (!response.data) {
        setExtraPointsPRErrMsg("Error submitting extra points PR")
        return
      }
      // console.log(JSON.stringify(response?.data))

      const index = userExams?.findIndex((ue) => {
        return ue.exam.id === userExam?.exam.id
      })
      const nextUserExams = userExams.map((ue, i) => {
        if (i === index) {
          return response.data.user_exam
        } else {
          return ue
        }
      })
      setUserExams(nextUserExams)
      setOpenExtraPointsPRModal(false)
      setExamToExtraPointsPR(undefined)
      setExtraPointsPR("")
      setToastList(
        toastList.concat({
          type: "success",
          children: `Extra points PR added successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setExtraPointsPRErrMsg("No server response")
        } else if (err.response?.status === 422) {
          console.log(err.response)
          setExtraPointsPRErrMsg(err.response.data.detail[0].msg)
        } else if (err.response?.status === 400) {
          console.log(err.response)
          setExtraPointsPRErrMsg(err.response.data.detail)
        } else {
          setExtraPointsPRErrMsg(
            "Submitting extra points PR failed with unknown error"
          )
        }
      } else {
        setExtraPointsPRErrMsg(
          "Submitting extra points PR failed with unknown error"
        )
      }
      extraPointsPRErrRef.current?.focus()
    }
  }

  const getEnumKeys = <T extends Object>(
    enumToDeconstruct: T
  ): Array<keyof typeof enumToDeconstruct> => {
    return Object.keys(enumToDeconstruct) as Array<
      keyof typeof enumToDeconstruct
    >
  }

  useEffect(() => {
    const PR_REGEX = /^[0-9]{1,6}$/
    setValidExtraPointsPR(PR_REGEX.test(extraPointsPR))
  }, [extraPointsPR])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date())
    }, 1000)

    getUserCourses()
    getUserLabs()
    getUserExams()

    return () => clearInterval(intervalId)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval_30s = setInterval(() => {
      updateLabsData()
    }, 30000)

    return () => {
      clearInterval(interval_30s)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLabs])

  const updateLabsData = async () => {
    if (userLabs === undefined) {
      return
    }
    if (
      userLabs.filter((userLab) => {
        var lab_state = (userLab.data as any).state as any
        if (
          lab_state.aws?.state === "PROVISIONING" ||
          lab_state.gcp?.state === "PROVISIONING" ||
          lab_state.aws?.state === "NUKING" ||
          lab_state.gcp?.state === "NUKING" ||
          (lab_state.aws?.state === undefined &&
            lab_state.gcp?.state === undefined)
        ) {
          return true
        }
        return false
      }).length === 0
    ) {
      return
    }

    try {
      const response = await LabsAuthedApi.labsReadLabsMe()
      if (!response.data) {
        setErrMsg("Get labs failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserLabs(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get labs failed with unknown error")
        }
      } else {
        setErrMsg("Get labs failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const buyLabExtention = async (
    slug: string,
    labExtensionDuration: number,
    quantity: string
  ) => {
    const q = parseInt(quantity)
    if (q < 1) {
      setExtendLabErrMsg("Invalid data, quantity must be larger than 0")
      return
    }

    try {
      const response = await LabsAuthedApi.labsBuyLab({
        slug: slug,
        duration: labExtensionDuration,
        quantity: parseInt(quantity),
      })
      if (!response.data) {
        setExtendLabErrMsg("Lab extension purchase failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      window.location.href = response.data.url
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setExtendLabErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setExtendLabErrMsg(err.response?.data.detail)
        } else if (err.response?.status === 404) {
          setExtendLabErrMsg("We could not find that lab")
        } else if (err.response?.status === 422) {
          setExtendLabErrMsg("Data validation error")
        } else {
          setExtendLabErrMsg("Lab extension purchase failed with unknown error")
        }
      } else {
        setExtendLabErrMsg("Lab extension purchase failed with unknown error")
      }
      extendLabErrRef.current?.focus()
    }
  }

  useEffect(() => {
    const q = parseInt(labExtensionQuantity)
    setValidLabExtensionQuantity(q >= 1)
  }, [labExtensionQuantity])

  const buyExamAttempt = async (slug: string, quantity: string) => {
    const q = parseInt(quantity)
    if (q < 1) {
      setExamAttemptErrMsg("Invalid data, quantity must be larger than 0")
      return
    }

    try {
      const response = await ExamsAuthedApi.examsBuyExam({
        slug: slug,
        quantity: parseInt(quantity),
      })
      if (!response.data) {
        setExamAttemptErrMsg("Exam attempt purchase failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      window.location.href = response.data.url
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setExamAttemptErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setExamAttemptErrMsg(err.response?.data.detail)
        } else if (err.response?.status === 404) {
          setExamAttemptErrMsg("We could not find that exam")
        } else if (err.response?.status === 422) {
          setExamAttemptErrMsg("Data validation error")
        } else {
          setExamAttemptErrMsg(
            "Exam attempt purchase failed with unknown error"
          )
        }
      } else {
        setExamAttemptErrMsg("Exam attempt purchase failed with unknown error")
      }
      examAttemptErrRef.current?.focus()
    }
  }

  useEffect(() => {
    const q = parseInt(examAttemptQuantity)
    setValidExamAttemptQuantity(q >= 1)
  }, [examAttemptQuantity])

  return (
    <section className="min-h-screen flex flex-col justify-between">
      <NavbarPrivate />
      <div className="mb-auto">
        <div className="container mx-auto mb-10">
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationTriangleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          {loadingCourses || loadingLabs || loadingExams ? (
            <Loading />
          ) : (
            <>
              <section id="courses">
                <h1 className="mt-10 mx-3 md:mx-0 text-2xl uppercase font-bold text-gray-800 dark:text-white">
                  Your Courses
                </h1>
                {userCourses?.length ? (
                  <>
                    {userCourses.map((userCourse) => (
                      <div key={userCourse.course.id}>
                        <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700 border-none">
                          <div className="flex justify-between">
                            <h5 className="text-lg font-semibold text-gray-800 dark:text-white">
                              {userCourse.course.title}
                            </h5>
                            {userCourse.course.changelog ? (
                              <>
                                <Badge
                                  theme={{
                                    root: {
                                      color: {
                                        gray: "bg-neutral-100 hover:bg-neutral-200 text-neutral-800 group-hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-600 dark:text-neutral-300 dark:group-hover:bg-neutral-600",
                                      },
                                    },
                                    icon: {
                                      on: "rounded-full py-1.5 px-3",
                                    },
                                  }}
                                  className="cursor-pointer"
                                  color="gray"
                                  size="sm"
                                  icon={VscRequestChanges}
                                  onClick={() => {
                                    setCourseToCourseChangelog(userCourse)
                                    setOpenCourseChangelogModal(true)
                                  }}
                                >
                                  <div className="hidden md:block">
                                    Changelog
                                  </div>
                                </Badge>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="md:flex">
                            <img
                              src={
                                {
                                  arte: ARTE_CAN,
                                  arta: ARTA_CAN,
                                  grte: GRTE_CAN,
                                  grta: GRTA_CAN,
                                }[userCourse.course.slug]
                              }
                              className="mx-auto md:mr-3 h-56 md:h-36"
                              alt="HackTricks Training Logo"
                            />
                            <div className="mt-3 md:mt-0 text-neutral-900 dark:text-white grow">
                              {userCourse.course.description}
                            </div>
                          </div>
                          <Progress
                            progress={calculateProgressCourse(userCourse)}
                            color="green"
                            labelProgress={true}
                            progressLabelPosition="outside"
                            textLabel="Course progress"
                            labelText={true}
                            textLabelPosition="outside"
                            theme={{
                              base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-neutral-600 h-2.5",
                            }}
                          />
                          <div className="mt-3 flex flex-row-reverse">
                            {(userCourse.data as any).last_seen ===
                            undefined ? (
                              <Button
                                type="button"
                                onClick={() => {
                                  if (
                                    userCourse.course.sections === null ||
                                    userCourse.course.sections === undefined
                                  ) {
                                    return
                                  }
                                  navigate(
                                    `/classroom/${userCourse.course.slug}#${userCourse.course.sections[0].slug}/${userCourse.course.sections[0].lessons[0].slug}`
                                  )
                                }}
                                className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                              >
                                Start course
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                onClick={() => {
                                  navigate(
                                    `/classroom/${userCourse.course.slug}#${
                                      (userCourse.data as any).last_seen.section
                                    }/${
                                      (userCourse.data as any).last_seen.lesson
                                    }?second=${
                                      (userCourse.data as any).last_seen.second
                                    }`
                                  )
                                }}
                                className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                              >
                                Continue where you left
                              </Button>
                            )}
                          </div>
                        </Card>
                      </div>
                    ))}
                    <Modal
                      show={openCourseChangelogModal}
                      dismissible
                      size="4xl"
                      popup
                      onClose={() => setOpenCourseChangelogModal(false)}
                      theme={{
                        content: {
                          inner:
                            "relative rounded-lg bg-white shadow dark:bg-neutral-900 flex flex-col max-h-[90vh]",
                        },
                      }}
                    >
                      <Modal.Header>
                        {courseToCourseChangelog?.course.slug.toUpperCase()}{" "}
                        Changelog
                      </Modal.Header>
                      <Modal.Body>
                        <Markdown
                          className="markdown-body !m-6"
                          remarkPlugins={[remarkGfm, rehypeAttrs]}
                        >
                          {courseToCourseChangelog?.course.changelog
                            ? courseToCourseChangelog?.course.changelog
                            : "This course has no changelog"}
                        </Markdown>
                      </Modal.Body>
                    </Modal>
                    <div className="mt-3">
                      <Link
                        to="/courses"
                        className="text-red-logo hover:text-red-900"
                      >
                        Check out our courses
                      </Link>
                    </div>
                  </>
                ) : (
                  <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700">
                    <Alert color="warning" icon={ExclamationTriangleIcon}>
                      <p>You are not enrolled in any courses yet</p>
                      <Link
                        to="/courses"
                        className="text-red-logo hover:text-red-900"
                      >
                        Check out our courses
                      </Link>
                    </Alert>
                  </Card>
                )}
              </section>

              <section id="labs">
                <Modal
                  show={openLabResetModal}
                  dismissible
                  size="md"
                  popup
                  onClose={() => setOpenLabResetModal(false)}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="text-center">
                      <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to reset lab{" "}
                        {labToReset?.lab.title}?
                      </h3>
                      <div className={!labResetErrMsg ? "sr-only" : ""}>
                        <Alert
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setLabResetErrMsg("")
                          }}
                        >
                          <span ref={labResetErrRef}>{labResetErrMsg}</span>
                        </Alert>
                      </div>
                      <Alert
                        className="mb-4"
                        color="warning"
                        icon={ExclamationTriangleIcon}
                      >
                        <p className="text-start">
                          A lab reset will provision a new{" "}
                          {labToReset?.lab.platform === "AWS"
                            ? "AWS account"
                            : labToReset?.lab.platform === "GCP"
                            ? "GCP Project"
                            : ""}
                          . Only 3 resets are allowed every 24 hours and they
                          must be at least 1 hour apart.
                        </p>
                        <p className="text-start font-semibold">
                          (
                          {
                            labToReset?.last_resets.filter(
                              (d) =>
                                new Date(d) >
                                new Date(
                                  now.getTime() - 1 * 1000 * 60 * 60 * 24
                                )
                            ).length
                          }
                          /3) resets in the last 24 hours.
                        </p>
                        <p className="text-start font-semibold">
                          (
                          {
                            labToReset?.last_resets.filter(
                              (d) =>
                                new Date(d) >
                                new Date(now.getTime() - 1 * 1000 * 60 * 60 * 1)
                            ).length
                          }
                          /1) resets in the last hour.
                        </p>
                        <p className="text-start text-xs mt-2">
                          If you continue to have issues please contact support
                          in the{" "}
                          <a
                            href="https://discord.gg/hRep4RUj7f"
                            target="_blank"
                            rel="noreferrer"
                            className="text-red-logo hover:text-red-900"
                          >
                            HackTricks discord server
                          </a>
                        </p>
                      </Alert>
                      <div className="flex justify-center gap-4">
                        <Button
                          color="failure"
                          onClick={() => resetLab(labToReset)}
                        >
                          Yes, I'm sure
                        </Button>
                        <Button
                          color="gray"
                          onClick={() => setOpenLabResetModal(false)}
                        >
                          No, cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={openLabExtendModal}
                  dismissible
                  size="lg"
                  popup
                  onClose={() => {
                    setOpenLabExtendModal(false)
                  }}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header className="p-5 border-b border-solid border-slate-200 rounded-t dark:border-neutral-600">
                    <span className="text-xl font-medium text-gray-900 dark:text-white">
                      Extend your {labToExtend?.lab.slug.toUpperCase()} lab time
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {labToExtend === undefined ? (
                        <>
                          <Alert color="failure" icon={ExclamationTriangleIcon}>
                            No lab to extend
                          </Alert>
                        </>
                      ) : (
                        <>
                          <fieldset className="text-center mt-4">
                            <legend className="mb-4 text-gray-900 dark:text-white">
                              Choose lab duration:
                            </legend>
                            <div className="flex justify-between grow mx-4">
                              <div className="flex items-center gap-2">
                                <Radio
                                  id="15-days"
                                  name="duration"
                                  value="15"
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) > 0 ||
                                      e.target.value === ""
                                    ) {
                                      setLabExtensionDuration(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  }}
                                />
                                <Label htmlFor="15-days">
                                  15 days ({labToExtend.lab.price15}€)
                                </Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <Radio
                                  id="30-days"
                                  name="duration"
                                  value="30"
                                  defaultChecked
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) > 0 ||
                                      e.target.value === ""
                                    ) {
                                      setLabExtensionDuration(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  }}
                                />
                                <Label htmlFor="30-days">
                                  30 days ({labToExtend.lab.price30}€)
                                </Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <Radio
                                  id="90-days"
                                  name="duration"
                                  value="90"
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) > 0 ||
                                      e.target.value === ""
                                    ) {
                                      setLabExtensionDuration(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  }}
                                />
                                <Label htmlFor="90-days">
                                  90 days ({labToExtend.lab.price90}€)
                                </Label>
                              </div>
                            </div>
                          </fieldset>
                          <div className="text-base leading-relaxed text-neutral-700 dark:text-white pt-6 mx-10">
                            Includes:
                            <ul className="list-disc list-inside">
                              <li>
                                {labExtensionDuration} days of access to 50+
                                labs
                              </li>
                            </ul>
                          </div>
                          <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                            Price:{" "}
                            {
                              {
                                15: labToExtend.lab.price15,
                                30: labToExtend.lab.price30,
                                90: labToExtend.lab.price90,
                              }[labExtensionDuration]
                            }
                            €
                          </div>

                          <div className="flex flex-col items-center pt-5">
                            <TextInput
                              id="labExtensionQuantity"
                              type="number"
                              placeholder="1"
                              addon="Quantity"
                              required={true}
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) > 0 ||
                                  e.target.value === ""
                                ) {
                                  setLabExtensionQuantity(e.target.value)
                                }
                              }}
                              onFocus={() => setLabExtensionQuantityFocus(true)}
                              onBlur={() => setLabExtensionQuantityFocus(false)}
                              value={labExtensionQuantity}
                              aria-invalid={
                                validLabExtensionQuantity ? "false" : "true"
                              }
                              color={
                                validLabExtensionQuantity
                                  ? ""
                                  : !labExtensionQuantity
                                  ? ""
                                  : "failure"
                              }
                              theme={{ field: { base: "relative w-16" } }}
                              helperText={
                                <>
                                  <span
                                    className={
                                      labExtensionQuantityFocus &&
                                      labExtensionQuantity &&
                                      !validLabExtensionQuantity
                                        ? ""
                                        : "sr-only"
                                    }
                                  >
                                    <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                                    Invalid quantity, must be greater than 0
                                  </span>
                                </>
                              }
                            />
                          </div>
                          {labToExtend.lab.promotion ? (
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                              <span className="text-red-700 dark:text-red-500 line-through">
                                Total:{" "}
                                {({
                                  15: labToExtend.lab.price15,
                                  30: labToExtend.lab.price30,
                                  90: labToExtend.lab.price90,
                                }[labExtensionDuration] ?? 0) *
                                  (labExtensionQuantity
                                    ? parseInt(labExtensionQuantity)
                                    : 0)}
                                €
                              </span>
                              <br />
                              Promotion: -{labToExtend.lab.promotion}€ <br />
                              <span className="text-green-600 dark:text-green-400">
                                New total:{" "}
                                {({
                                  15: labToExtend.lab.price15,
                                  30: labToExtend.lab.price30,
                                  90: labToExtend.lab.price90,
                                }[labExtensionDuration] ?? 0) *
                                  (labExtensionQuantity
                                    ? parseInt(labExtensionQuantity)
                                    : 0) -
                                  labToExtend.lab.promotion}
                                €
                              </span>
                            </div>
                          ) : (
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                              Total:{" "}
                              {({
                                15: labToExtend.lab.price15,
                                30: labToExtend.lab.price30,
                                90: labToExtend.lab.price90,
                              }[labExtensionDuration] ?? 0) *
                                (labExtensionQuantity
                                  ? parseInt(labExtensionQuantity)
                                  : 0)}
                              €
                            </div>
                          )}
                          <div className={!extendLabErrMsg ? "sr-only" : ""}>
                            <Alert
                              className="mt-5"
                              color="failure"
                              icon={ExclamationTriangleIcon}
                              onDismiss={function onDismiss() {
                                setExtendLabErrMsg("")
                              }}
                            >
                              <span ref={extendLabErrRef}>
                                {extendLabErrMsg}
                              </span>
                            </Alert>
                          </div>
                          <div className="flex flex-col items-center pt-5">
                            <Button
                              className="items-center text-neutral-700 dark:text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                              type="button"
                              onClick={() =>
                                buyLabExtention(
                                  labToExtend.lab.slug,
                                  labExtensionDuration,
                                  labExtensionQuantity
                                )
                              }
                              disabled={!validLabExtensionQuantity}
                            >
                              Buy now
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>

                <h1 className="mt-10 mx-3 md:mx-0 text-2xl uppercase font-bold text-gray-800 dark:text-white">
                  Your Labs
                </h1>
                {userLabs?.length ? (
                  <>
                    {userLabs.map((userLab) => (
                      <div key={userLab.lab.id}>
                        <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700 border-none">
                          <div className="md:flex">
                            <h5 className="text-lg font-semibold text-gray-800 dark:text-white text-center md:text-start my-auto">
                              {userLab.lab.title}
                            </h5>
                            <Tooltip
                              content={
                                {
                                  LOCKED:
                                    "Lab provisioning can take up to 30 minutes",
                                  PROVISIONING:
                                    "Lab provisioning can take up to 30 minutes",
                                  PROVISIONED: "",
                                  DIRTY: "",
                                  NUKING: "",
                                  NUKED: "",
                                }[
                                  (userLab.data as any).state?.[
                                    userLab.lab.platform.toLowerCase()
                                  ]?.state as string
                                ] ??
                                "Lab provisioning starts automatically once the voucher is activated"
                              }
                              placement="top"
                              theme={{
                                target: "my-auto",
                              }}
                              className={
                                {
                                  LOCKED: "font-sans",
                                  PROVISIONING: "font-sans",
                                  PROVISIONED: "font-sans invisible opacity-0",
                                  DIRTY: "font-sans invisible opacity-0",
                                  NUKING: "font-sans invisible opacity-0",
                                  NUKED: "font-sans invisible opacity-0",
                                }[
                                  (userLab.data as any).state?.[
                                    userLab.lab.platform.toLowerCase()
                                  ]?.state as string
                                ] ?? "font-sans"
                              }
                            >
                              <Badge
                                className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                color={
                                  {
                                    LOCKED: "warning",
                                    PROVISIONING: "warning",
                                    PROVISIONED: "success",
                                    DIRTY: "failure",
                                    NUKING: "warning",
                                    NUKED: "failure",
                                  }[
                                    (userLab.data as any).state?.[
                                      userLab.lab.platform.toLowerCase()
                                    ]?.state as string
                                  ] ?? "info"
                                }
                              >
                                {{
                                  LOCKED: "Initiating lab provisioning",
                                  PROVISIONING: "Provisioning lab",
                                  PROVISIONED:
                                    "Lab provisioned (" +
                                    ((userLab.data as any).state?.[
                                      userLab.lab.platform.toLowerCase()
                                    ]?.[
                                      userLab.lab.platform === "AWS"
                                        ? "account_id"
                                        : userLab.lab.platform === "GCP"
                                        ? "project_id"
                                        : ""
                                    ] as string) +
                                    ")",
                                  DIRTY: "Error provisioning lab",
                                  NUKING: "Destroying lab",
                                  NUKED: "Lab destroyed",
                                }[
                                  (userLab.data as any).state?.[
                                    userLab.lab.platform.toLowerCase()
                                  ]?.state as string
                                ] ?? "Lab not provisioned"}
                              </Badge>
                            </Tooltip>

                            {((userLab.data as any).state?.[
                              userLab.lab.platform.toLowerCase()
                            ]?.state as string) === "PROVISIONED" ||
                            ((userLab.data as any).state?.[
                              userLab.lab.platform.toLowerCase()
                            ]?.state as string) === "DIRTY" ? (
                              <div className="mt-3 md:ml-3 md:flex md:my-auto flex justify-center">
                                {userLab.last_resets.filter(
                                  (d) =>
                                    new Date(d) >
                                    new Date(
                                      now.getTime() - 1 * 1000 * 60 * 60 * 24
                                    )
                                ).length >= 3 ? (
                                  <Tooltip
                                    content={`You have reached the max resets in 24 hours (3) - Time left ${timeConversion(
                                      1000 * 60 * 60 * 24 -
                                        (now.getTime() -
                                          new Date(
                                            userLab.last_resets.sort(
                                              (a, b) =>
                                                new Date(a).getTime() -
                                                new Date(b).getTime()
                                            )[0]
                                          ).getTime())
                                    )}`}
                                    placement="top"
                                    className="font-sans"
                                  >
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setLabToReset(userLab)
                                        setOpenLabResetModal(true)
                                      }}
                                      disabled={true}
                                      className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                    >
                                      <BiReset fontSize="24" /> Reset Lab
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    {userLab.last_resets.filter(
                                      (d) =>
                                        new Date(d) >
                                        new Date(
                                          now.getTime() - 1 * 1000 * 60 * 60 * 1
                                        )
                                    ).length >= 1 ? (
                                      <Tooltip
                                        content={`You have reached the max resets in one hour (1) - Time left ${timeConversion(
                                          1000 * 60 * 60 -
                                            (now.getTime() -
                                              new Date(
                                                userLab.last_resets.sort(
                                                  (a, b) =>
                                                    new Date(b).getTime() -
                                                    new Date(a).getTime()
                                                )[0]
                                              ).getTime())
                                        )}`}
                                        placement="top"
                                        className="font-sans"
                                      >
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            setLabToReset(userLab)
                                            setOpenLabResetModal(true)
                                          }}
                                          disabled={true}
                                          className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                        >
                                          <BiReset fontSize="24" /> Reset Lab
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          setLabToReset(userLab)
                                          setOpenLabResetModal(true)
                                        }}
                                        className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                      >
                                        <BiReset fontSize="24" /> Reset Lab
                                      </Button>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          {/* {userLab.lab.prereleased ? (
                          <Alert
                            className="my-3"
                            color="warning"
                            icon={ExclamationTriangleIcon}
                          >
                            This lab is in pre-release <br />
                            The predicted release date is{" "}
                            {userLab.lab.release_date}
                          </Alert>
                        ) : (
                          <></>
                        )} */}
                          <div className="md:flex">
                            <img
                              src={
                                {
                                  arte: ARTE_CAN,
                                  arta: ARTA_CAN,
                                  grte: GRTE_CAN,
                                  grta: GRTA_CAN,
                                }[userLab.lab.course.slug]
                              }
                              className="mx-auto md:mr-3 h-56 md:h-36"
                              alt="HackTricks Training Logo"
                            />
                            <div className="mt-3 md:mt-0 text-neutral-900 dark:text-white grow">
                              {userLab.lab.description}
                            </div>
                          </div>
                          <div className="flex justify-items-center md:justify-items-start mt-3 md:mt-2">
                            <div className="text-neutral-900 dark:text-white self-center">
                              Days left {calculateTimeLeftLab(userLab)}
                            </div>
                            <div className="ml-5 justify-start block">
                              <Button
                                type="button"
                                onClick={() => {
                                  setLabToExtend(userLab)
                                  setOpenLabExtendModal(true)
                                }}
                                className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                              >
                                Extend Lab Time
                              </Button>
                            </div>
                          </div>

                          {userLab.lab.flags !== undefined &&
                          userLab.lab.flags !== null ? (
                            <Progress
                              progress={
                                userLab.lab.flags.length === 0
                                  ? 100
                                  : Math.round(
                                      (labsProgress[userLab.lab.slug] /
                                        userLab.lab.flags.length) *
                                        100
                                    )
                              }
                              color="green"
                              labelProgress={true}
                              progressLabelPosition="outside"
                              textLabel={`Lab progress (${
                                labsProgress[userLab.lab.slug]
                              }/${userLab.lab.flags.length} flags)`}
                              labelText={true}
                              textLabelPosition="outside"
                              theme={{
                                base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-neutral-600 h-2.5",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Card>
                      </div>
                    ))}
                  </>
                ) : (
                  <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700">
                    <Alert color="warning" icon={ExclamationTriangleIcon}>
                      <p>You are not enrolled in any labs yet</p>
                      {/* <Link
                        to="/labs"
                        className="text-red-logo hover:text-red-900"
                      >
                        Check out our labs
                      </Link> */}
                    </Alert>
                  </Card>
                )}
              </section>

              <section id="exams">
                <Modal
                  show={openScheduleExamModal}
                  dismissible
                  size="2xl"
                  popup
                  onClose={() => setOpenScheduleExamModal(false)}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="text-center">
                      <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to schedule your{" "}
                        {examToSchedule?.exam.title} exam?
                      </h3>
                      <Alert color="warning" icon={ExclamationTriangleIcon}>
                        <span>
                          Exams can only be scheduled within the next 90 days
                        </span>
                      </Alert>
                      <Datepicker
                        showClearButton={false}
                        showTodayButton={false}
                        className=""
                        inline
                        maxDate={
                          new Date(
                            new Date().setDate(new Date().getDate() + 90)
                          )
                        }
                        minDate={new Date()}
                        weekStart={1}
                        onSelectedDateChanged={(date) => {
                          setExamToScheduleDate(date)
                        }}
                        theme={{
                          root: {
                            base: "relative",
                          },
                          popup: {
                            root: {
                              inner:
                                "inline-block rounded-lg bg-white p-4 shadow-none dark:bg-neutral-700",
                            },
                            header: {
                              selectors: {
                                button: {
                                  base: "text-sm rounded-lg text-neutral-900 dark:text-white bg-white dark:bg-neutral-700 font-semibold py-2.5 px-5 hover:bg-neutral-100 dark:hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-200 view-switch",
                                },
                              },
                            },
                          },
                          views: {
                            days: {
                              header: {
                                title:
                                  "dow h-6 text-center text-sm font-medium leading-6 text-neutral-500 dark:text-neutral-400",
                              },
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-neutral-900 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 ",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            months: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-neutral-900 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            years: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 text-neutral-900",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            decades: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 text-neutral-900",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                          },
                        }}
                      />
                      <div className="max-w-md mb-4 mx-auto" id="select">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="time"
                            value="Select the exam start time (UTC)"
                          />
                        </div>
                        <Select
                          id="time"
                          required
                          defaultValue={examToScheduleTime}
                          onChange={(e) => {
                            setExamToScheduleTime(
                              ExamTimeEnum[
                                e.target.value as keyof typeof ExamTimeEnum
                              ]
                            )
                          }}
                          theme={{
                            field: {
                              select: {
                                colors: {
                                  gray: "bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-800 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo",
                                },
                              },
                            },
                          }}
                        >
                          {getEnumKeys(ExamTimeEnum).map((key, index) => (
                            <option key={index} value={key}>
                              {ExamTimeEnum[key]}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className={!scheduleExamErrMsg ? "sr-only" : ""}>
                        <Alert
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setScheduleExamErrMsg("")
                          }}
                        >
                          <span ref={scheduleExamErrRef}>
                            {scheduleExamErrMsg}
                          </span>
                        </Alert>
                      </div>
                      <div className="flex justify-center gap-4 mt-4">
                        <Button
                          color="failure"
                          onClick={() => scheduleExam(examToSchedule)}
                          isProcessing={isExamScheduling}
                          disabled={isExamScheduling}
                        >
                          Yes, I'm sure
                        </Button>
                        <Button
                          color="gray"
                          onClick={() => setOpenScheduleExamModal(false)}
                        >
                          No, cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={openRescheduleExamModal}
                  dismissible
                  size="2xl"
                  popup
                  onClose={() => setOpenRescheduleExamModal(false)}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="text-center">
                      <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to reschedule your{" "}
                        {examToReschedule?.exam.title} exam?
                      </h3>
                      <Alert
                        color="warning"
                        className="text-start"
                        icon={ExclamationTriangleIcon}
                      >
                        <span>
                          Exams can only be rescheduled within the next 90 days
                        </span>
                        <p className="font-semibold">
                          The exam can only be rescheduled 3 time (
                          {examToReschedule?.reschedules.length}
                          /3) reschedules.
                        </p>
                      </Alert>
                      <Datepicker
                        showClearButton={false}
                        showTodayButton={false}
                        className=""
                        inline
                        maxDate={
                          new Date(
                            new Date().setDate(new Date().getDate() + 90)
                          )
                        }
                        minDate={new Date()}
                        weekStart={1}
                        onSelectedDateChanged={(date) => {
                          setExamToRescheduleDate(date)
                        }}
                        theme={{
                          root: {
                            base: "relative",
                          },
                          popup: {
                            root: {
                              inner:
                                "inline-block rounded-lg bg-white p-4 shadow-none dark:bg-neutral-700",
                            },
                            header: {
                              selectors: {
                                button: {
                                  base: "text-sm rounded-lg text-neutral-900 dark:text-white bg-white dark:bg-neutral-700 font-semibold py-2.5 px-5 hover:bg-neutral-100 dark:hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-200 view-switch",
                                },
                              },
                            },
                          },
                          views: {
                            days: {
                              header: {
                                title:
                                  "dow h-6 text-center text-sm font-medium leading-6 text-neutral-500 dark:text-neutral-400",
                              },
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-neutral-900 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 ",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            months: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-neutral-900 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            years: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 text-neutral-900",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                            decades: {
                              items: {
                                item: {
                                  base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-600 text-neutral-900",
                                  selected:
                                    "bg-red-logo text-white hover:bg-red-900 dark:hover:bg-red-900",
                                  disabled:
                                    "text-neutral-500 dark:text-neutral-500",
                                },
                              },
                            },
                          },
                        }}
                      />
                      <div className="max-w-md mb-4 mx-auto" id="select">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="time"
                            value="Select the exam start time (UTC)"
                          />
                        </div>
                        <Select
                          id="time"
                          required
                          defaultValue={examToRescheduleTime}
                          onChange={(e) => {
                            setExamToRescheduleTime(
                              ExamTimeEnum[
                                e.target.value as keyof typeof ExamTimeEnum
                              ]
                            )
                          }}
                          theme={{
                            field: {
                              select: {
                                colors: {
                                  gray: "bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-800 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo",
                                },
                              },
                            },
                          }}
                        >
                          {getEnumKeys(ExamTimeEnum).map((key, index) => (
                            <option key={index} value={key}>
                              {ExamTimeEnum[key]}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className={!rescheduleExamErrMsg ? "sr-only" : ""}>
                        <Alert
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setRescheduleExamErrMsg("")
                          }}
                        >
                          <span ref={rescheduleExamErrRef}>
                            {rescheduleExamErrMsg}
                          </span>
                        </Alert>
                      </div>
                      <div className="flex justify-center gap-4 mt-4">
                        <Button
                          color="failure"
                          onClick={() => rescheduleExam(examToReschedule)}
                          isProcessing={isExamRescheduling}
                          disabled={isExamRescheduling}
                        >
                          Yes, I'm sure
                        </Button>
                        <Button
                          color="gray"
                          onClick={() => setOpenRescheduleExamModal(false)}
                        >
                          No, cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={openExtraPointsPRModal}
                  dismissible
                  size="2xl"
                  popup
                  onClose={() => {
                    setOpenExtraPointsPRModal(false)
                  }}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you submit an extra points PR for your{" "}
                          {examToExtraPointsPR?.exam.title} exam?
                        </h3>
                      </div>
                      <p className="text-gray-500 dark:text-gray-300 mb-4">
                        You can get extra point for your exam by submitting a
                        Pull Request to{" "}
                        <a href="https://github.com/carlospolop/hacktricks-cloud">
                          https://github.com/carlospolop/hacktricks-cloud
                        </a>
                        . Submitting one PR will allow you to pass the exam by
                        finding only 2 out of the 3 flags.
                      </p>
                      <Alert
                        color="info"
                        icon={HiInformationCircle}
                        className="mb-4"
                      >
                        <span>
                          In order for a PR to be eligible it must meet the
                          following requirements:
                        </span>
                        <ul className="list-inside list-disc text-left">
                          <li>
                            The PR title must contain{" "}
                            <code>
                              {examToExtraPointsPR?.exam.slug}-{"<"}
                              your_username{">"}
                            </code>{" "}
                            (without the <code>{"<>"}</code>)
                          </li>
                          <li>The PR must be reviewed and merged</li>
                          <li>
                            The PR must never have been used for this purpose
                          </li>
                          <li>
                            It must be merged before the start of your exam
                          </li>
                        </ul>
                      </Alert>

                      <div className="max-w-md mb-4 mx-auto" id="select">
                        <div className="mb-2 block">
                          <div className="mb-2 block">
                            <Label
                              htmlFor="extraPointsPR"
                              value="Extra points PR"
                            />
                          </div>
                          <TextInput
                            id="extraPointsPR"
                            type="text"
                            placeholder="PR number"
                            autoComplete="extraPointsPR"
                            required={true}
                            onChange={(e) => {
                              setExtraPointsPR(e.target.value)
                            }}
                            onFocus={() => setExtraPointsPRFocus(true)}
                            onBlur={() => setExtraPointsPRFocus(false)}
                            value={extraPointsPR}
                            aria-invalid={validExtraPointsPR ? "false" : "true"}
                            color={
                              validExtraPointsPR
                                ? "success"
                                : !extraPointsPR
                                ? ""
                                : "failure"
                            }
                            helperText={
                              <>
                                <span
                                  className={
                                    extraPointsPRFocus &&
                                    extraPointsPR &&
                                    !validExtraPointsPR
                                      ? ""
                                      : "sr-only"
                                  }
                                >
                                  <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                                  Invalid PR number:
                                  <br />
                                  Must be number up to 6 digits long.
                                </span>
                              </>
                            }
                          />
                        </div>
                      </div>
                      <div className={!extraPointsPRErrMsg ? "sr-only" : ""}>
                        <Alert
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setExtraPointsPRErrMsg("")
                          }}
                        >
                          <span ref={extraPointsPRErrRef}>
                            {extraPointsPRErrMsg}
                          </span>
                        </Alert>
                      </div>
                      <div className="flex justify-center gap-4 mt-4">
                        <Button
                          color="failure"
                          onClick={() =>
                            submitExtraPointsPR(examToExtraPointsPR)
                          }
                        >
                          Yes, I'm sure
                        </Button>
                        <Button
                          color="gray"
                          onClick={() => setOpenExtraPointsPRModal(false)}
                        >
                          No, cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={openExamAttemptModal}
                  dismissible
                  size="lg"
                  popup
                  onClose={() => {
                    setOpenExamAttemptModal(false)
                  }}
                  theme={{
                    content: {
                      inner:
                        "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                    },
                  }}
                >
                  <Modal.Header className="p-5 border-b border-solid border-slate-200 rounded-t dark:border-neutral-600">
                    <span className="text-xl font-medium text-gray-900 dark:text-white">
                      Purchase {examAttemptToBuy?.exam.slug.toUpperCase()} exam
                      attempt
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {examAttemptToBuy === undefined ? (
                        <>
                          <Alert color="failure" icon={ExclamationTriangleIcon}>
                            No exam to purchase
                          </Alert>
                        </>
                      ) : (
                        <>
                          <div className="text-base leading-relaxed text-neutral-700 dark:text-white pt-6 mx-10">
                            Includes:
                            <ul className="list-disc list-inside">
                              <li>
                                1 {examAttemptToBuy.exam.title} examination
                                attempt
                              </li>
                            </ul>
                          </div>
                          <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                            Price: {examAttemptToBuy.exam.price}€
                          </div>

                          <div className="flex flex-col items-center pt-5">
                            <TextInput
                              id="examAttemptQuantity"
                              type="number"
                              placeholder="1"
                              addon="Quantity"
                              required={true}
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) > 0 ||
                                  e.target.value === ""
                                ) {
                                  setExamAttemptQuantity(e.target.value)
                                }
                              }}
                              onFocus={() => setExamAttemptQuantityFocus(true)}
                              onBlur={() => setExamAttemptQuantityFocus(false)}
                              value={examAttemptQuantity}
                              aria-invalid={
                                validExamAttemptQuantity ? "false" : "true"
                              }
                              color={
                                validExamAttemptQuantity
                                  ? ""
                                  : !examAttemptQuantity
                                  ? ""
                                  : "failure"
                              }
                              theme={{ field: { base: "relative w-16" } }}
                              helperText={
                                <>
                                  <span
                                    className={
                                      examAttemptQuantityFocus &&
                                      examAttemptQuantity &&
                                      !validExamAttemptQuantity
                                        ? ""
                                        : "sr-only"
                                    }
                                  >
                                    <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                                    Invalid quantity, must be greater than 0
                                  </span>
                                </>
                              }
                            />
                          </div>
                          {examAttemptToBuy.exam.promotion ? (
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                              <span className="text-red-700 dark:text-red-500 line-through">
                                Total:{" "}
                                {examAttemptToBuy.exam.price *
                                  (examAttemptQuantity
                                    ? parseInt(examAttemptQuantity)
                                    : 0)}
                                €
                              </span>
                              <br />
                              Promotion: -{
                                examAttemptToBuy.exam.promotion
                              }€ <br />
                              <span className="text-green-600 dark:text-green-400">
                                New total:{" "}
                                {examAttemptToBuy.exam.price *
                                  (examAttemptQuantity
                                    ? parseInt(examAttemptQuantity)
                                    : 0) -
                                  examAttemptToBuy.exam.promotion}
                                €
                              </span>
                            </div>
                          ) : (
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center pt-5">
                              Total:{" "}
                              {examAttemptToBuy.exam.price *
                                (examAttemptQuantity
                                  ? parseInt(examAttemptQuantity)
                                  : 0)}
                              €
                            </div>
                          )}
                          <div className={!examAttemptErrMsg ? "sr-only" : ""}>
                            <Alert
                              className="mt-5"
                              color="failure"
                              icon={ExclamationTriangleIcon}
                              onDismiss={function onDismiss() {
                                setExamAttemptErrMsg("")
                              }}
                            >
                              <span ref={examAttemptErrRef}>
                                {examAttemptErrMsg}
                              </span>
                            </Alert>
                          </div>
                          <div className="flex flex-col items-center pt-5">
                            <Button
                              className="items-center text-neutral-700 dark:text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                              type="button"
                              onClick={() =>
                                buyExamAttempt(
                                  examAttemptToBuy.exam.slug,
                                  examAttemptQuantity
                                )
                              }
                              disabled={!validExamAttemptQuantity}
                            >
                              Buy now
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>

                <h1 className="mt-10 mx-3 md:mx-0 text-2xl uppercase font-bold text-gray-800 dark:text-white">
                  Your Exams
                </h1>
                {userExams?.length ? (
                  <>
                    {userExams.map((userExam) => (
                      <div key={userExam.exam.id}>
                        <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700 border-none">
                          <div className="md:flex">
                            <h5 className="text-lg font-semibold text-gray-800 dark:text-white text-center md:text-start my-auto">
                              {userExam.exam.title}
                            </h5>
                            {new Date(userExam.exam_end as string) >=
                            new Date() ? (
                              <Tooltip
                                content={
                                  {
                                    LOCKED:
                                      "Exam provisioning can take up to 15 minutes",
                                    PROVISIONING:
                                      "Exam provisioning can take up to 15 minutes",
                                    PROVISIONED: "",
                                    DIRTY: "",
                                    NUKING: "",
                                    NUKED: "",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ??
                                  "Exam provisioned will start 6 hours before the exam"
                                }
                                placement="top"
                                theme={{
                                  target: "my-auto",
                                }}
                                className={
                                  {
                                    LOCKED: "font-sans",
                                    PROVISIONING: "font-sans",
                                    PROVISIONED:
                                      "font-sans invisible opacity-0",
                                    DIRTY: "font-sans invisible opacity-0",
                                    NUKING: "font-sans invisible opacity-0",
                                    NUKED: "font-sans invisible opacity-0",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ?? "font-sans"
                                }
                              >
                                <Badge
                                  className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                  color={
                                    {
                                      LOCKED: "warning",
                                      PROVISIONING: "warning",
                                      PROVISIONED: "success",
                                      DIRTY: "failure",
                                      NUKING: "warning",
                                      NUKED: "failure",
                                    }[
                                      (userExam.data as any).state?.[
                                        userExam.exam.platform.toLowerCase()
                                      ]?.state as string
                                    ] ?? "info"
                                  }
                                >
                                  {{
                                    LOCKED:
                                      "Initiating exam environment provisioning",
                                    PROVISIONING:
                                      "Provisioning exam environment",
                                    PROVISIONED:
                                      "Exam environment provisioned (" +
                                      ((userExam.data as any).state?.[
                                        userExam.exam.platform.toLowerCase()
                                      ]?.[
                                        userExam.exam.platform === "AWS"
                                          ? "account_id"
                                          : userExam.exam.platform === "GCP"
                                          ? "project_id"
                                          : ""
                                      ] as string) +
                                      ")",
                                    DIRTY:
                                      "Exam environment marked for cleanup",
                                    NUKING: "Destroying exam environment",
                                    NUKED: "Exam environment destroyed",
                                  }[
                                    (userExam.data as any).state?.[
                                      userExam.exam.platform.toLowerCase()
                                    ]?.state as string
                                  ] ?? "Exam environment not provisioned"}
                                </Badge>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            <Badge
                              className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                              color={
                                (userExam.exam_start === null ||
                                  userExam.exam_start === undefined) &&
                                (userExam.exam_end === null ||
                                  userExam.exam_end === undefined)
                                  ? "warning"
                                  : new Date(userExam.exam_start as string) >=
                                    new Date()
                                  ? "success"
                                  : new Date(userExam.exam_end as string) >=
                                    new Date()
                                  ? "purple"
                                  : "failure"
                              }
                            >
                              {(userExam.exam_start === null ||
                                userExam.exam_start === undefined) &&
                              (userExam.exam_end === null ||
                                userExam.exam_end === undefined) ? (
                                "Exam not scheduled"
                              ) : (
                                <>
                                  {new Date(userExam.exam_start as string) >=
                                  new Date() ? (
                                    "Exam scheduled"
                                  ) : (
                                    <>
                                      {new Date(userExam.exam_end as string) >=
                                      new Date()
                                        ? "Exam started"
                                        : "Exam ended"}
                                    </>
                                  )}
                                </>
                              )}
                            </Badge>
                            {userExam.extra_points_pr !== null ? (
                              <Badge
                                className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                color="success"
                              >
                                Extra Points PR submitted
                              </Badge>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="md:flex">
                            <img
                              src={
                                {
                                  arte: ARTE_CAN,
                                  arta: ARTA_CAN,
                                  grte: GRTE_CAN,
                                  grta: GRTA_CAN,
                                }[userExam.exam.slug]
                              }
                              className="mx-auto md:mr-3 h-56 md:h-36"
                              alt="HackTricks Training Logo"
                            />
                            <div className="mt-3 md:mt-0 text-neutral-900 dark:text-white grow">
                              {userExam.exam.description}
                            </div>
                          </div>

                          {(userExam.exam_start === null ||
                            userExam.exam_start === undefined) &&
                          (userExam.exam_end === null ||
                            userExam.exam_end === undefined) ? (
                            <>
                              <div className="text-neutral-900 dark:text-white my-auto">
                                <div className="mb-3 text-lg font-semibold text-center md:text-start">
                                  Time left to schedule exam:
                                </div>
                                <div className="md:flex justify-items-center md:justify-items-start">
                                  <Timer
                                    delta={calculateTimeLeftExam(userExam)}
                                  />
                                  <div className="md:ml-5 justify-center md:justify-start flex md:block mt-3 md:mt-2">
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setExamToSchedule(userExam)
                                        setOpenScheduleExamModal(true)
                                      }}
                                      className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                    >
                                      Schedule exam
                                    </Button>
                                  </div>

                                  {userExam.extra_points_pr === null ? (
                                    <>
                                      <div className="md:ml-2 justify-center md:justify-start flex md:block mt-3 md:mt-2">
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            setExamToExtraPointsPR(userExam)
                                            setOpenExtraPointsPRModal(true)
                                          }}
                                          className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                        >
                                          Add extra points PR
                                        </Button>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {new Date(userExam.exam_start as string) >=
                              new Date() ? (
                                <div className="text-neutral-900 dark:text-white my-auto">
                                  <div className="mb-3 text-lg font-semibold text-center md:text-start">
                                    Exam scheduled to start in:
                                  </div>
                                  <div className="md:flex justify-items-center md:justify-items-start">
                                    <Timer
                                      delta={calculateTimeLeftExam(userExam)}
                                    />
                                    <div className="md:ml-5 justify-center md:justify-start flex md:block mt-3 md:mt-2">
                                      {userExam.reschedules.length >= 3 ? (
                                        <Tooltip
                                          content={`You have reached the maximum number of exam reschedules (3)`}
                                          placement="top"
                                          className="font-sans"
                                        >
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              setExamToReschedule(userExam)
                                              setOpenRescheduleExamModal(true)
                                            }}
                                            disabled={true}
                                            className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                          >
                                            Reschedule exam
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        <>
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              setExamToReschedule(userExam)
                                              setOpenRescheduleExamModal(true)
                                            }}
                                            className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                          >
                                            Reschedule exam
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                    {userExam.extra_points_pr === null ? (
                                      <>
                                        <div className="md:ml-5 justify-center md:justify-start flex md:block mt-3 md:mt-2">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              setExamToExtraPointsPR(userExam)
                                              setOpenExtraPointsPRModal(true)
                                            }}
                                            className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                          >
                                            Add extra points PR
                                          </Button>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {new Date(userExam.exam_end as string) >=
                                  new Date() ? (
                                    <div className="text-neutral-900 dark:text-white my-auto">
                                      <div className="mb-3 text-lg font-semibold text-center md:text-start">
                                        Exam started time left:
                                      </div>
                                      <div className="md:flex justify-items-center md:justify-items-start">
                                        <Timer
                                          delta={calculateTimeLeftExam(
                                            userExam
                                          )}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="text-neutral-900 dark:text-white my-auto">
                                      <div className="mb-3 text-lg font-semibold text-center md:text-start">
                                        Exam ended:
                                      </div>
                                      <div className="md:flex justify-items-center md:justify-items-start">
                                        <Timer
                                          delta={calculateTimeLeftExam(
                                            userExam
                                          )}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {userExam.exam_start !== null &&
                          userExam.exam_start !== undefined &&
                          new Date(userExam.exam_start) <= new Date() &&
                          userExam.exam.examflags ? (
                            <>
                              <Progress
                                progress={Math.round(
                                  (examsProgress[userExam.exam.slug] /
                                    userExam.exam.examflags.length) *
                                    100
                                )}
                                color="green"
                                labelProgress={true}
                                progressLabelPosition="outside"
                                textLabel={`Exam progress (${
                                  examsProgress[userExam.exam.slug]
                                }/${userExam.exam.examflags.length} flags)`}
                                labelText={true}
                                textLabelPosition="outside"
                                theme={{
                                  base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-neutral-600 h-2.5",
                                }}
                              />

                              {examsProgress[userExam.exam.slug] ===
                                userExam.exam.examflags.length ||
                              (examsProgress[userExam.exam.slug] ===
                                userExam.exam.examflags.length - 1 &&
                                userExam.extra_points_pr) ? (
                                <>
                                  <Alert color="success" icon={LuPartyPopper}>
                                    <p>
                                      Congratulations you have submitted all the
                                      flags and therefore passed the exam
                                    </p>
                                    {userExam.data &&
                                    (userExam.data as any).certificate ? (
                                      <span
                                        className="hover:underline hover:cursor-pointer font-medium"
                                        onClick={() => {
                                          navigate(
                                            `/certificates/${
                                              (userExam.data as any).certificate
                                            }`
                                          )
                                        }}
                                      >
                                        View your certificate
                                      </span>
                                    ) : (
                                      <span className="font-medium">
                                        Generate your certificate in the exam
                                        room
                                      </span>
                                    )}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  {userExam.exam_end !== null &&
                                  userExam.exam_end !== undefined &&
                                  new Date(userExam.exam_end) <= new Date() ? (
                                    <>
                                      <Alert
                                        color="failure"
                                        icon={ExclamationCircleIcon}
                                      >
                                        <p>
                                          Unfortunately, your exam time has ran
                                          out and you did not manage to submit
                                          all the flags.
                                        </p>
                                        <div className="mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              setExamAttemptToBuy(userExam)
                                              setOpenExamAttemptModal(true)
                                            }}
                                            className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                          >
                                            Purchase exam attempt
                                          </Button>
                                        </div>
                                      </Alert>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                              <div className="mt-3 flex flex-row-reverse">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    navigate(`/examroom/${userExam.exam.slug}`)
                                  }}
                                  className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                >
                                  Go to exam
                                </Button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card>
                      </div>
                    ))}
                  </>
                ) : (
                  <Card className="mt-10 mx-3 md:mx-0 dark:bg-neutral-700">
                    <Alert color="warning" icon={ExclamationTriangleIcon}>
                      <p>You are not enrolled in any exams yet</p>
                      {/* <Link
                        to="/exams"
                        className="text-red-logo hover:text-red-900"
                      >
                        Check out our exams
                      </Link> */}
                    </Alert>
                  </Card>
                )}
              </section>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Dashboard
